import React, { useState } from "react";
import bookData from "./Data/E-book.json";
import { FaSearch } from "react-icons/fa";
import "./pages.css";

function BookDownload() {
  const data = bookData;
  const [search, setSearch] = useState("");

  return (
    <main id="test" className="a_main-container">
      <div className="main-title text-color">
        <h3>E-Book Downloaded Users</h3>
      </div>
      <div>
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value)}
          ></input>
        </form>
      </div>
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Downloaded on</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.Name.toLowerCase().includes(search);
              })
              .map((item) => (
                <tr>
                  <td>{item.ID}</td>
                  <td>{item.Name}</td>
                  <td>{item.Email}</td>
                  <td>{item.Phone}</td>
                  <td>{item["Downloaded on"]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default BookDownload;
