import { getFirestore, collection, addDoc, setDoc, getDocs, orderBy, query,where,deleteDoc } from "firebase/firestore";

// Generates a new coupon with specified details
const firestore = getFirestore();

const generateCoupon = async (
  code,
  priceAfterDiscount,
  validFrom,
  validTill,
  limit,
  credits
) => {
  const couponDetails = {
    code: code,
    "price-after-discount": priceAfterDiscount,
    "valid-from": validFrom,
    "valid-till": validTill,
    limit: limit,
    redeemed:0,
    credits: credits
  };
  
  try {
    const couponsCollectionRef = collection(firestore, "coupons");
    
    console.log("reached here")
    // Query to check if the coupon code already exists
    const querySnapshot = await getDocs(
      query(couponsCollectionRef, where("code", "==", code))
    );
    
        
    console.log("reached here")
    if (!querySnapshot.empty) {
      // Coupon code already exists, show alert message
      alert("Coupon code already exists");
      return;
    }
        console.log("reached here")
    
    // Coupon code doesn't exist, add it to Firestore
    await addDoc(couponsCollectionRef, couponDetails);
            console.log("reached here")
    console.log("Data written to Firestore successfully!");
  } catch (error) {
    console.error("Error writing document: ", error);
  }
};


// Fetches all users and counts the number of free and paid users
const fetchAllUsers = async () => {
    let paidUsers = 0;
    let freeUsers = 0;
    try {
        const firestore = getFirestore();
        const q = query(collection(firestore, 'users'));
        const querySnapshot = await getDocs(q);
        const docsList = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()['accountType'] === 'free')
                freeUsers++;
            else if (doc.data()['accountType'] === 'paid')
                paidUsers++;
            docsList.push(doc.data());
        });
        return { "usersList": docsList, "freeUsers": freeUsers, "paidUsers": paidUsers };
    } catch (error) {
        console.log(error);
    }
}

// Fetches all tests taken by users
const fetchUserTests = async () => {
    try {
        const firestore = await getFirestore();
        const usersCollection = await collection(firestore, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const userTests = [];
        for (const userDoc of usersSnapshot.docs) {
            const userId = userDoc.id;
            const userRef = userDoc.ref;
            const userTestsCollectionRef = await collection(userRef, "tests-taken");
            const userTestsSnapshot = await getDocs(userTestsCollectionRef);
            if (!userTestsSnapshot.size !== 0) {
                userTestsSnapshot.docs.forEach(test => {
                    userTests.push({
                        userId: userId,
                        testId: test.id,
                        time: test.data().time,
                        name: test.data()['test-name']
                    });
                });
            }
        }
        userTests.sort((a, b) => new Date(b.time) - new Date(a.time));
        return { userTests }
    } catch (error) {
        console.error("Error fetching user tests:", error);
    }
};

// Adds a new blog post
const addBlogPost = async (title, contentLink) => {
    const newBlogPost = {
        "title": title,
        "content": contentLink,
        "createdTime": new Date().toISOString()
    };
    try {
        const firestore = await getFirestore();
        const collectionReference = await collection(firestore, "blog-posts");
        await addDoc(collectionReference, newBlogPost)
        console.log("Successfully added new blog post")
    } catch (error) {
        console.log(error)
    }
}

// Retrieves blog posts ordered by the creation time
const getBlogPosts = async () => {
    try {
        const firestore = await getFirestore();
        const collectionReference = collection(firestore, "blog-posts");
        const querySnapshot = await getDocs(query(collectionReference, orderBy("createdTime", "desc")));
        const blogPosts = [];
        querySnapshot.forEach((doc) => {
            const postData = {
                id: doc.id,
                ...doc.data()
            };
            blogPosts.push(postData);
        });
        return blogPosts;
    } catch (error) {
        console.error("Error retrieving blog posts:", error);
        return [];
    }
};



// Retrieves the download count for books
const getDownloadsCount = async () => {
    try {
        const firestore = await getFirestore();
        const collectionRef = await collection(firestore, "books-downloads");
        const documentRef = await getDocs(collectionRef);
        return documentRef.docs[0].data();
    } catch (error) {
        console.log('Something went wrong: try again')
    }
}

// Function to retrieve all coupons and return them in JSON form
const getAllCoupons = async () => {
  try {
    const couponsCollectionRef = collection(firestore, "coupons");
    const querySnapshot = await getDocs(couponsCollectionRef);

    const coupons = [];
    querySnapshot.forEach((doc) => {
      coupons.push({ id: doc.id, ...doc.data() });
    });

    return coupons;
  } catch (error) {
    console.error("Error retrieving coupons: ", error);
    return [];
  }
};

// Function to delete a coupon given its coupon code
const deleteCoupon = async (code) => {
  try {
    const couponsCollectionRef = collection(firestore, "coupons");
    const querySnapshot = await getDocs(
      query(couponsCollectionRef, where("code", "==", code))
    );

    if (querySnapshot.empty) {
      console.log("Coupon not found.");
      return;
    }

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Coupon deleted successfully.");
    });
  } catch (error) {
    console.error("Error deleting coupon: ", error);
  }
};


export {
    fetchAllUsers,
    fetchUserTests,
    generateCoupon,
    addBlogPost,
    getBlogPosts,
    getDownloadsCount,
    getAllCoupons,
    deleteCoupon
}
