import React from "react";
import './postCard.css'
const PostCard = ({cardContent}) => {
    console.log(cardContent);
    const IframeComponent = ({ src, width, height }) => {
        return <iframe className="post-iframe" src={src} width={width} height={height}  />;
      };
    return (
    <div className='featured-post'>
        <IframeComponent src={cardContent}  height={270} width={260}/>
    </div>
    );
};

export default PostCard;