import React, { useState } from 'react';
import {
    auth,setPersistence,
    registerWithEmailAndPassword,
    signInWithGoogle,
    loginWithEmailAndPassword,
    isSignedIn,
    logout
} from '../../../../services/authService.js';
import bslogo from './../../../../Assets/logo192.png'
import './login.css';
// import { RememberMe } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [name, setName] = useState('');
    const [errorVar, setError] = useState(false)
    const [rememberMe, setRememberMe] = useState(true)
    
    const location = useLocation();
    const navigate = useNavigate();

    const intendedDestination = location.state?.intendedDestination || '/';
    
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    // const handleNameChange = (e) => setName(e.target.value);

    // const handleSignUp = async () => {
    //     let result = await registerWithEmailAndPassword(name, email, password);
    //     console.log(result)
    // };

    const toastOptions = {

        position:"top-right",
        limit:1,
        pauseOnHover:false

    }

    const handleLogin = async () => {
        try{
            await loginWithEmailAndPassword(email, password,{'rememberMe':rememberMe});
            toast.success('Log In successfull',toastOptions);
            navigate(intendedDestination);
            console.log("logged in successfully");
        }
        catch(error){
            toast.error('Invalid Credentials',toastOptions);
            setError(!errorVar)
        }
    };


    const toggleRememberMe = () => {
        setRememberMe(!rememberMe)
    };

    const handleGoogleSignIn = async () => {
        try {
            const { success, user, error } = await signInWithGoogle({ 'rememberMe': rememberMe });
            if (success) {
                toast.success("Log In successful!",toastOptions);
                navigate(intendedDestination);
            } else {
                if (error.code === "auth/popup-closed-by-user") {
                    console.log("User closed the login dialog");
                } else {
                    console.error("Login failed:", error.message);
                }
            }
        } catch (error) {
            // Handle sign-in errors here
            console.error("Sign-in failed:", error);
            // You can also show a toast message or handle the error in UI if needed
        }
    };
    
    const showPassword = () => {
        const checkbox = document.getElementById('showpasscheckbox');
        const passwordInput = document.getElementById('password');

        // Check if the checkbox is checked
        if (checkbox.checked) {
            // Change the type of the input field to 'text' to show the password
            passwordInput.type = 'text';
        } else {
            // Change the type of the input field to 'password' to hide the password
            passwordInput.type = 'password';
        }
    };

    return (
        <div className='login-container'>
            <div id='logo-contaniner'>
                    <img src={bslogo} alt="bslogo" id='logo-image' height={50} width={50}/>
                    <p id='logo-title'>BranchSelector</p>
            </div>
        <div className='form-content'>
            <div className="form-content-login">
                <span>Login</span>
     
            </div>
            <div className='inputs'>
                <label id="email-lable" htmlFor="username">EMAIL ADDRESS</label>
                <input type="text" id="" name="username" className="form-input" onChange={handleEmailChange}/>
            </div>
            
            <div className='inputs'>
                <label id="email-lable" htmlFor="password">PASSWORD</label>
                <input type="password" id="password" name="pasword" className="form-input"  onChange={handlePasswordChange}/>
            </div>

        <div className='checks'>
        <div id='remember'>
            <input type="checkbox" name="rememberme" id="" onChange={toggleRememberMe} defaultChecked = {true} checked={rememberMe}/>
            <label htmlFor="rememberme">Remember Me</label>
        </div>
        <div id='showpass'>
            <input type="checkbox" name="showpass" id="showpasscheckbox" onChange={showPassword}/>
            <label htmlFor="showpass">Show Password</label>
        </div>
        </div>
            
        <button className='btn-main' id='login-btn' onClick={handleLogin}>Login</button>

        </div>
        <span>or</span>
        <button className='login-with-google' onClick={handleGoogleSignIn}>
                
                <img className = 'image-holder' src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg' alt='Google Logo'/>
          
                <span className='login-with-google-text'>
                Sign in with Google
                </span>
         
        </button>
        
        </div>
    );
};

export default Login;
