import React, { useEffect, useState } from "react";
import "../../Styles/navbar.css";
import logo from './../../Assets/logo192.png';
import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import PersonIcon from '@mui/icons-material/Person';
import { isSignedIn, addAuthChangeListener } from "../../services/authService";
import { getCurrentUserInfo } from "../../services/userService";

export default function Navbar() {
  const [userInfo, setUserInfo] = useState(null); // State to store user info

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoData = await getCurrentUserInfo(); // Call async function
        setUserInfo(userInfoData); // Set user info to state
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchData(); // Call the async function when component mounts

    // Add authentication listener
    const unsubscribe = addAuthChangeListener(async (user) => {
      if (user) {
        try {
          const userInfoData = await getCurrentUserInfo();
          setUserInfo(userInfoData); // Update user info when authentication changes
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      } else {
        setUserInfo(null);
      }
    });

    return () => {
      unsubscribe(); // Cleanup listener
    };
  }, []);

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("active");
  };

  return (
    <nav className="navbar">
      <div className="nav-left">
        <button
          className="nav-btn"
          onClick={showNavbar}>
          <FaBars/>
        </button>
      </div>
      <div className="navbar-brand">
        <NavLink to="/">
          <img src={logo} id="logo-b" height="70" width="70" alt="branch selector" />
        </NavLink>
        <NavLink to='/' id="brandName">BranchSelector</NavLink>
      </div>

      <div className="navbar-links" ref={navRef}>
        <ul>
          <li><NavLink className={({ isActive }) => `${isActive ? 'active-link' : 'navLink'}`} onClick={showNavbar} to="/Tests">Tests</NavLink></li>
          <li><NavLink className={({ isActive }) => `${isActive ? 'active-link' : 'navLink'}`} onClick={showNavbar} to="/contactus">Contact</NavLink></li>
          <li><NavLink className={({ isActive }) => `${isActive ? 'active-link' : 'navLink'}`} onClick={showNavbar} to="/Blog">Blog</NavLink></li>
          <li><NavLink className={({ isActive }) => `${isActive ? 'active-link' : 'navLink'}`} onClick={showNavbar} to="/booking">Book Appointment</NavLink></li>
          <li><NavLink className={({ isActive }) => `${isActive ? 'active-link' : 'navLink'}`} onClick={showNavbar} to="/login"><span className='loginSignUp'>Login</span></NavLink></li>
          <li><NavLink className={({ isActive }) => `${isActive ? 'active-link' : 'navLink'}`} onClick={showNavbar} to="/signup"><span className='loginSignUp'>Sign Up</span></NavLink></li>
        </ul>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}><FaTimes /></button>
      </div>

      <div className="profile" onClick={()=>navigate('/dashboard')}>
        {(userInfo && userInfo.photoUrl !== '') ? (
          <img className='profilePicture' src={userInfo.photoUrl} alt="Profile" />
        ) : (
          <PersonIcon style={{ fontSize: 28, font: "bold", padding: "0.5vh" }} />
        )}
      </div>
    </nav>
  );
}
