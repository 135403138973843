import '../../../Styles/iqstyles.css'
const IQQuestions=({ke,item,onchange,nextpage})=>{
    // const ims=item.img;
    return (
    <div  className = 'questionComponent' >
                                    
            {/* <div className='questionBox'><p id='questionTxt'>{item.question}</p></div> */}
            <div style={{textAlign:'center',paddingLeft:'15px'}}>
            <img style={{width:"-webkit-fill-available", maxWidth:'300px' }}  src={item.question} alt="Logo" /> <br/>
            <img className={"iqoptions"}  src={item.A} alt="Logo" onClick={()=>{onchange(ke-1,1); nextpage()}} />
            <img className="iqoptions"  src={item.B} alt="Logo" onClick={()=>{onchange(ke-1,2); nextpage()}} />
            <img className="iqoptions"  src={item.C} alt="Logo" onClick={()=>{onchange(ke-1,3); nextpage()}} />
            <img className="iqoptions"  src={item.D} alt="Logo" onClick={()=>{onchange(ke-1,4); nextpage()}} />
            <img className="iqoptions"  src={item.E} alt="Logo" onClick={()=>{onchange(ke-1,5); nextpage()}} />
            <img className="iqoptions"  src={item.F} alt="Logo" onClick={()=>{onchange(ke-1,6); nextpage()}} />
            </div>

             
        
        </div>
    )
    }
    
    export default IQQuestions
;