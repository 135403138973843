import React from 'react';
import Layout from './Layout';

export default function EBook() {

  const downloadFile = (fileName) => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/Downloads/' + fileName;
    link.download = fileName;
    link.click();

  };


  return (
    <Layout>
      <div className="container">
        <div className='bookContainer'>
          <br />
          <br />
          <h1 className='downloadTxt'>Download Engineering Lighthouse eBook</h1>
          <button className='downloadBtn'  onClick={() => downloadFile('Enginering_Lighthouse_v3.0.pdf')} >
            Download
          </button>
          <br />
          <br />
          <br />
          <h1 className='downloadTxt'>Download Career Path Chart</h1>
          <button className='downloadBtn' onClick={() => downloadFile('SurePass_Career_2023.pdf')}>
            Download
          </button>
        </div>
      </div>
    </Layout>
  );
}
