import React from "react";
import "./pages.css";
function Blog() {
  return (
    <main id="test" className="a_main-container">
      <div className="main-title text-color">
        <h3>Blog</h3>
      </div>
      <div>
        <form className="blog-input">
          <input type="text" placeholder="Enter facebook post link" />
          <button type="submit">Add</button>
        </form>
      </div>
      <div className="a_blog">
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
        <div className="blog-card"></div>
      </div>
    </main>
  );
}

export default Blog;
