import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = () => {
    const location = useLocation();

    useEffect(() => {
        // Extract the page name from the pathname
        const pageTitle = getPageTitle(location.pathname);

        // Set the document title
        document.title = pageTitle || 'BranchSelector';
    }, [location]);

    return null; // This component doesn't render anything
};

export default PageTitle;

// Helper function to get the page title based on the URL
const getPageTitle = (pathname) => {
    switch (pathname) {
        case '/Tests':
            return 'Tests - BranchSelector';
        case '/contactus':
            return 'Contact - BranchSelector';
        case '/Blog':
            return 'Blog - BranchSelector';
        case '/booking':
            return 'Book Appointment - BranchSelector';
        // Add more cases for additional routes
        default:
            return null; // Default title for unknown routes
    }
};
