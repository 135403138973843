import axios from "axios";
import Layout from "../../Common/Layout";
import React, { useEffect, useState } from "react";
import StreamTestQuestion from "./StreamTestQuestion";
import { useNavigate } from "react-router-dom";
import stream from "./stream.json";

import {
  canTakeTest,
  getCurrentUserInfo,
  newTestTaken,
} from "../../../services/userService";
import { getDefaultProfilePic } from "../testService";
import { TestSideBar } from "../TestSideBar/TestSideBar";
import { toast } from "react-toastify";

async function fetchQuestions() {
  const response = await fetch("http://localhost:5402/streams");
  const data = await response.json();
  return data;
}

const StreamTestPage = () => {
  const [fetchedQuestions, setFetchedQuestions] = useState(stream);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 5;

  const [userDetails, setUserDetails] = useState(null);

  var isLastPage;
  const [radioValues, setRadioValues] = useState([]);

  const handleRadioChange = (index, value) => {
    setRadioValues((prevValues) => {
      const newRadioValues = [...prevValues];
      newRadioValues[index] = value;
      return newRadioValues;
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDetails = await getCurrentUserInfo();
        console.log(userDetails);
        setUserDetails(userDetails);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchUserData();
  }, userDetails);
  useEffect(() => {
    const checkAllowed = async () => {
      try {
        const validity = await canTakeTest();
        if (validity) {
        } else {
          navigate("/dashboard");
          toast.error("No more free tests left on the account");
        }
      } catch (error) {
        console.error("Not premium", error);
        setLoading(false);
      }
    };

    checkAllowed();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []); //remove after adding api

  // useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const questions = await fetchQuestions();
  //         setFetchedQuestions(questions);
  //         setLoading(false);
  //       } catch (error) {
  //         console.error('Error fetching questions:', error);
  //         setLoading(false);
  //       }
  //     };

  //     fetchData();
  // }, []);

  const handlePrevPage = () => {
    window.scroll(0, 0);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if(!isAllAnswered())
    {
      alert("Please answer all the questions! ");
      return;
    }
    window.scroll(0, 0);
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(fetchedQuestions.length / questionsPerPage)
      )
    );
  };

  const isAllAnswered = ()=>
  {
  const startIndex = (currentPage - 1) * questionsPerPage;
  const endIndex = startIndex + questionsPerPage;
  for(var i = startIndex; i<endIndex; i++)
  {
    if(radioValues[i]!==1&radioValues[i]!==2&radioValues[i]!==3)
    return false;
  }
  return true
  }

  const navigate = useNavigate();
  const renderQuestions = () => {
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    isLastPage =
      currentPage === Math.ceil(fetchedQuestions.length / questionsPerPage);
    return fetchedQuestions
      .slice(startIndex, endIndex)
      .map((item, index) => (
        <StreamTestQuestion
          key={startIndex + index}
          item={item}
          radioValue={radioValues[startIndex + index]}
          onRadioChange={(value) =>
            handleRadioChange(startIndex + index, value)
          }
        />
      ));
  };

  const sendApi = () => {
      if(!isAllAnswered())
  {
    alert("Please answer all the questions! ");
    return;
  }
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    const stream = {
      Arts: 0,
      Science: 0,
      Commerce: 0,
    };
    radioValues.forEach((element) => {
      if (element === 1) {
        stream.Arts = stream.Arts + 1;
      } else if (element === 2) {
        stream.Science = stream.Science + 1;
      } else if (element === 3) {
        stream.Commerce = stream.Commerce + 1;
      }
    });

    const streams = [
      ["Science", stream.Science],
      ["Commerce", stream.Commerce],
      ["Arts", stream.Arts],
    ];

    user["stream"] = streams;
    axios
      .post(
        "https://branchselctor--get-results.anish98821.workers.dev/?query=stream",
        user,
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // const data=response.data;
        navigate("/classX/results", { state: { stream } });
      })
      .catch((error) => {
        console.log(error);
        alert("Somthing went wrong plz try again later");
      });

    newTestTaken("Stream Test", "tempResultStream");
  };
  return (
    <Layout>
      {userDetails != null && !loading ? (
        <div className="test-main-container">
          <TestSideBar
            profilePic={
              userDetails["photoUrl"]
                ? userDetails["photoUrl"]
                : getDefaultProfilePic()
            }
            userName={
              userDetails["displayName"] ? userDetails["displayName"] : "User"
            }
            email={
              userDetails["email"] ? userDetails["email"] : "user@mail.com"
            }
            testName={"Stream Test"}
            answeredQuestions={5}
            totalQuestions={15}
          />
          <div className="test-container">
            {currentPage == 1 && (
              <div>
                <h1 id="instruct">Instructions!</h1>
                <br />
                <div id="instructionTxt">
                  <div id="instructionContents">
                    <ul>
                      <li>
                        &nbsp;While answering the test, be honest. Answer to
                        express, not to impress.
                      </li>
                      <li>
                        &nbsp;Do not ask for suggestions with friends/check
                        online for answers while answering the questions.
                      </li>
                      <li>
                        &nbsp;Take the test 2 times to get better understanding
                        of your preferences.
                      </li>
                      <li>
                        &nbsp;Read the e-Book from the Downloads section to
                        understand the various branches and the career
                        opportunities.
                      </li>
                    </ul>
                  </div>
                </div>{" "}
              </div>
            )}
            <br />
            {fetchedQuestions && (
              <h3 id="questionHeading">
                Please answer the following questions ({currentPage}/
                {Math.ceil(fetchedQuestions.length / questionsPerPage)})
              </h3>
            )}
            <br />
            {loading ? (
              <p id="loadTxt">Loading...</p>
            ) : (
              <div>
                <div>{renderQuestions()}</div>
                <br />
                <div className="takeQuizBtns">
                  <div className="backNextBtns">
                    <button
                      id="backBtn"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Back
                    </button>
                    {!isLastPage && (
                      <button
                        id="nextBtn"
                        onClick={handleNextPage}
                        disabled={
                          currentPage ===
                          Math.ceil(fetchedQuestions.length / questionsPerPage)
                        }
                      >
                        Next
                      </button>
                    )}
                  </div>
                  <div className="getResultBtn">
                    {isLastPage && (
                      <button id="resultBtn" onClick={() => sendApi()}>
                        Get Result
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="loading-screen">Loading...</div>
      )}
    </Layout>
  );
};

export default StreamTestPage;
