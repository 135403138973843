import { getTestLogo } from '../testService'
import './TestSideBar.css'

const TestSideBar = ({profilePic,userName,email,testName, answeredQuestions, totalQuestions}) => {

var testImage = getTestLogo(testName)

return(
<section className='test-side-bar'>

<div className="test-side-bar-box">
    <div className= "profile-section">
        <img src ={profilePic} className="profile-picture"></img>
        <span className='profile-name'>{userName}</span>
        <span className="profile-email">{email}</span>

    </div>

    <img src = {testImage} className="current-test-image">
    </img>

    <span className= "in-progress-text">In Progress</span>
    <strong className = "current-test-name">{testName}</strong>

    <div className='progress-section'>

        <span className="progress-text">{answeredQuestions} out of {totalQuestions} answered</span>
    </div>

</div>

</section>)
}

export {TestSideBar}

