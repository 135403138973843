export function InterestInfocard() {
    return (
        <div className="card-container">
            <div className="main-heading">What does this mean to you?<br/> Continue reading to know more! </div>

            <div className="trait">
                <h2 className="trait-heading">Realistic</h2>
                <p className="trait-content">
                    Realistic individuals are practical and hands-on, preferring to work with tangible objects and solve concrete problems.
                    They excel in occupations that involve manual labor, technical skills, or physical activities, such as construction, engineering, or mechanics.
                    Realistic individuals have a strong spatial awareness and enjoy activities such as woodworking, gardening, or outdoor sports.
                    They are often detail-oriented and focused on achieving measurable results in their work or projects.
                    Realistic individuals value efficiency and effectiveness, seeking solutions that are practical and functional.
                </p>
                <p className="trait-keywords">
                    Keywords: Practical, Hands-on, Tangible, Technical, Efficient
                </p>
                <p className="trait-professions">
                    Professions: Carpenter, Electrician, Mechanic, Construction Worker, Plumber
                </p>
            </div>

            <div className="trait">
                <h2 className="trait-heading">Investigative</h2>
                <p className="trait-content">
                    Investigative individuals are analytical and curious, with a keen interest in understanding how things work and solving complex problems.
                    They excel in occupations that require research, data analysis, and critical thinking, such as science, technology, or academia.
                    Investigative individuals enjoy exploring abstract concepts and theories, conducting experiments, or delving into intellectual pursuits.
                    They are often independent thinkers who thrive in environments that stimulate their intellect and allow for exploration and discovery.
                    Investigative individuals value accuracy and precision, seeking to uncover truths and expand their knowledge.
                </p>
                <p className="trait-keywords">
                    Keywords: Analytical, Curious, Intellectual, Research-oriented, Logical
                </p>
                <p className="trait-professions">
                    Professions: Scientist (e.g., Biologist, Chemist), Research Analyst, Engineer (e.g., Civil, Engineer, Software Engineer), Detective, Data Scientist
                </p>
            </div>

            <div className="trait">
                <h2 className="trait-heading">Artistic</h2>
                <p className="trait-content">
                    Artistic individuals are creative and expressive, with a passion for artistic endeavors such as painting, writing, music, or theater.
                    They excel in occupations that allow for self-expression and creativity, such as graphic design, writing, or performing arts.
                    Artistic individuals have a unique perspective and a knack for seeing beauty and meaning in the world around them.
                    They are often sensitive and emotionally attuned, using their art as a means of communication and personal reflection.
                    Artistic individuals value originality and authenticity, seeking to inspire and evoke emotion through their creative endeavors.
                </p>
                <p className="trait-keywords">
                    Keywords: Creative, Expressive, Imaginative, Original, Aesthetic
                </p>
                <p className="trait-professions">
                    Professions: Graphic Designer, Writer/Author, Musician, Actor/Actress, Interior Designer
                </p>
            </div>

            <div className="trait">
                <h2 className="trait-heading">Social</h2>
                <p className="trait-content">
                    Social individuals are outgoing and empathetic, with a strong desire to connect with others and make a positive impact on their lives.
                    They excel in occupations that involve helping, supporting, or collaborating with others, such as teaching, counseling, or community organizing.
                    Social individuals enjoy socializing, building relationships, and working in teams to achieve common goals.
                    They are often good listeners and communicators, able to understand others' perspectives and build rapport easily.
                    Social individuals value compassion and altruism, seeking to foster cooperation and harmony in their interactions with others.
                </p>
                <p className="trait-keywords">
                    Keywords: Outgoing, Empathetic, Collaborative, Compassionate, Interpersonal
                </p>
                <p className="trait-professions">
                    Professions: Teacher/Educator, Counselor/Therapist, Social Worker, Human Resources Specialist, Event Planner
                </p>
            </div>

            <div className="trait">
                <h2 className="trait-heading">Enterprising</h2>
                <p className="trait-content">
                    Enterprising individuals are ambitious and goal-oriented, with a drive to succeed and make a mark in their chosen field.
                    They excel in occupations that involve leadership, entrepreneurship, or sales, such as business management, marketing, or sales.
                    Enterprising individuals enjoy taking initiative, seizing opportunities, and pursuing their goals with determination and confidence.
                    They are often persuasive communicators and strategic thinkers, able to identify opportunities and navigate challenges effectively.
                    Enterprising individuals value achievement and recognition, seeking to make a tangible impact and advance their careers or businesses.
                </p>
                <p className="trait-keywords">
                    Keywords: Ambitious, Goal-oriented, Leadership, Entrepreneurial, Persuasive
                </p>
                <p className="trait-professions">
                    Professions: Entrepreneur, Sales Representative, Marketing Manager, Business Consultant, Real Estate Agent
                </p>
            </div>

            <div className="trait">
                <h2 className="trait-heading">Conventional</h2>
                <p className="trait-content">
                    Conventional individuals are orderly and detail-oriented, preferring structured environments and adhering to established norms and procedures.
                    They excel in occupations that require organization, attention to detail, and adherence to rules and regulations, such as accounting, administration, or data entry.
                    Conventional individuals thrive in roles that involve planning, scheduling, and maintaining order, ensuring that tasks are completed efficiently and accurately.
                    They are often reliable and responsible, following guidelines and protocols to achieve consistency and quality in their work.
                    Conventional individuals value stability and predictability, seeking environments where they can apply their skills methodically and achieve predictable outcomes.
                </p>
                <p className="trait-keywords">
                    Keywords: Orderly, Detail-oriented, Methodical, Structured, Rule-bound
                </p>
                <p className="trait-professions">
                    Professions: Accountant, Administrative Assistant, Bank Teller, Legal Secretary, Quality Control Inspector
                </p>
            </div>
        </div>
    );
}
