import axios from "axios";
import Layout from "../../Common/Layout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IQQuestions from "./IQTestQuestions";

import {
  canTakeTest,
  getCurrentUserInfo,
  newTestTaken,
} from "../../../services/userService";
import { getDefaultProfilePic } from "../testService";
import { TestSideBar } from "../TestSideBar/TestSideBar";
import { toast } from "react-toastify";

async function fetchQuestions() {
  const response = await fetch(
    "https://branchselector--get-questions.anish98821.workers.dev/?query=iq"
  );
  var data = await response.json();
  data = data.questions;
  // console.log(data)
  return data;
}

const IQTestPage = () => {
  const [fetchedQuestions, setFetchedQuestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 1;
  const [answers, setanswers] = useState([]);
  const [corectans, setcorect] = useState([
    1, 5, 6, 6, 4, 5, 5, 3, 4, 4, 1, 1, 2, 3, 6, 2, 2, 3, 6, 6, 5, 6, 1, 6, 5,
    6, 6, 1, 1, 3, 5,
  ]);

  const [userDetails, setUserDetails] = useState(null);

  let pointsscored = 0;
  var isLastPage;
  const [radioValues, setRadioValues] = useState([]);

  const handleOptionschange = (index, option) => {
    const newArray = [...answers]; // Create a  copy of the array
    newArray[index] = option;
    setanswers(newArray);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questions = await fetchQuestions();
        const newarr = questions.slice(0, 30);
        setFetchedQuestions(newarr);
        setLoading(false);
        createarray();
      } catch (error) {
        console.error("Error fetching questions:", error);
        setLoading(false);
      }
    };

    fetchData();
    //   createarray is used to create a array of same size as questions array where we can store selected options
    const createarray = async () => {
      for (let i = 0; i < 30; i++) {
        setanswers((prev) => [...prev, 0]);
      }
    };
  }, []);

  useEffect(() => {
    const checkAllowed = async () => {
      try {
        const validity = await canTakeTest();
        if (validity) {
        } else {
          navigate("/dashboard");
          toast.error("No more free tests left on the account");
        }
      } catch (error) {
        console.error("Not premium", error);
        setLoading(false);
      }
    };

    checkAllowed();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDetails = await getCurrentUserInfo();
        console.log(userDetails);
        setUserDetails(userDetails);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchUserData();
  }, userDetails);

  const handlePrevPage = () => {
    window.scroll(0, 0);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scroll(0, 0);
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(fetchedQuestions.length / questionsPerPage)
      )
    );
  };

  const navigate = useNavigate();
  const renderQuestions = () => {
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    isLastPage =
      currentPage === Math.ceil(fetchedQuestions.length / questionsPerPage);
    var questions = fetchedQuestions
      .slice(startIndex, endIndex)
      .map((item, index) => (
        <IQQuestions
          ke={startIndex + index + 1}
          item={item}
          onchange={handleOptionschange}
          nextpage={handleNextPage}
          answers={answers}
        />
      ));
    return questions;
  };

  const sendApi = () => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === corectans[i]) {
        pointsscored = pointsscored + 5;
      } else {
        pointsscored = pointsscored - 1;
      }
    }
    //  console.log(answers);
    user["IQ"] = pointsscored;
    axios
      .post(
        "https://branchselctor--get-results.anish98821.workers.dev/?query=IQ",
        user,
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        navigate("/IQ/IQresults", { state: pointsscored });
      })
      .catch((error) => {
        console.log(error);
        alert("Somthing went wrong plz try again later");
      });

    newTestTaken("IQ Test", "tempResult");
  };

  return (
    <Layout>
      {userDetails != null && !loading ? (
        <div className="test-main-container">
          <TestSideBar
            profilePic={
              userDetails["photoUrl"]
                ? userDetails["photoUrl"]
                : getDefaultProfilePic()
            }
            userName={
              userDetails["displayName"] ? userDetails["displayName"] : "User"
            }
            email={
              userDetails["email"] ? userDetails["email"] : "user@mail.com"
            }
            testName={"IQ Test"}
            answeredQuestions={5}
            totalQuestions={15}
          />

          <div className="test-container">
            {currentPage == 1 && (
              <div>
                <h1 id="instruct">Instructions!</h1>
                <br />
                <p id="instructionTxt">
                  <ul>
                    <li>
                      &nbsp;While answering the test, be honest. Answer to
                      express, not to impress.
                    </li>
                    <li>
                      &nbsp;Do not ask for suggestions with friends/check online
                      for answers while answering the questions.
                    </li>
                    <li>
                      &nbsp;Take the test 2 times to get better understanding of
                      your preferences.
                    </li>
                    {/* <li>&nbsp;Read the e-Book from the Downloads section to understand the various branches and the career opportunities.</li> */}
                  </ul>
                </p>{" "}
              </div>
            )}
            <br />
            {fetchedQuestions && (
              <h3 id="questionHeading">
                Please answer the following questions ({currentPage}/
                {Math.ceil(fetchedQuestions.length / questionsPerPage)})
              </h3>
            )}
            <br />
            {loading ? (
              <p id="loadTxt">Loading...</p>
            ) : (
              <div>
                <div>{renderQuestions()}</div>
                <br />
                <div className="takeQuizBtns">
                  <div className="backNextBtns">
                    <button
                      id="backBtn"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Back
                    </button>
                    {!isLastPage && (
                      <button
                        id="nextBtn"
                        onClick={() => {
                          handleNextPage();
                          handleOptionschange(0);
                        }}
                        disabled={
                          currentPage ===
                          Math.ceil(fetchedQuestions.length / questionsPerPage)
                        }
                      >
                        Next
                      </button>
                    )}
                  </div>
                  <div className="getResultBtn">
                    {isLastPage && (
                      <button
                        id="resultBtn"
                        onClick={() => {
                          sendApi();
                        }}
                      >
                        Get Result
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="loading-screen">Loading...</div>
      )}
    </Layout>
  );
};

export default IQTestPage;
