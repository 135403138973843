import React, { useState } from "react";
import testData from "./Data/test.json";
import { FaSearch } from "react-icons/fa";
import "./pages.css";
function TestTaken() {
  const data = testData;
  const [search, setSearch] = useState("");

  return (
    <main id="test" className="a_main-container">
      <div className="main-title text-color">
        <h3>Tests Taken By Users</h3>
      </div>
      <div>
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value)}
          ></input>
        </form>
      </div>
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Test</th>
              <th>Report</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.name.toLowerCase().includes(search);
              })
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile}</td>
                  <td>{item.test}</td>
                  <td>{item.date}</td>
                  <td className="report-button">
                    <button>View</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default TestTaken;
