import axios from "axios";
import Layout from "../../Common/Layout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VARKTestQuestion from "./VARKTestQuestion";

import {
  canTakeTest,
  getCurrentUserInfo,
  newTestTaken,
} from "../../../services/userService";
import { getDefaultProfilePic } from "../testService";
import { TestSideBar } from "../TestSideBar/TestSideBar";
import { toast } from "react-toastify";

async function fetchQuestions() {
  const response = await fetch(
    "https://branchselector--get-questions.anish98821.workers.dev/?query=vark"
  );
  var data = await response.json();
  data = data.questions;
  console.log(data);
  return data;
}

const VARKTestPage = () => {
  const [fetchedQuestions, setFetchedQuestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 5;
  const [userDetails, setUserDetails] = useState(null);

  var isLastPage;
  const [radioValues, setRadioValues] = useState([]);

  const handleRadioChange = (index, value) => {
    setRadioValues((prevValues) => {
      const newRadioValues = [...prevValues];
      newRadioValues[index] = value;
      return newRadioValues;
    });
  };

  useEffect(() => {
    const checkAllowed = async () => {
      try {
        const validity = await canTakeTest();
        if (validity) {

        } else {
          navigate("/dashboard");
          toast.error("No more free tests left on the account");
        }
      } catch (error) {
        console.error("Not premium", error);
        setLoading(false);
      }
    };

    checkAllowed();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questions = await fetchQuestions();
        setFetchedQuestions(questions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching questions:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDetails = await getCurrentUserInfo();
        console.log(userDetails);
        setUserDetails(userDetails);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchUserData();
  }, userDetails);
  useEffect(() => {
    if (fetchedQuestions) {
      const initRadioValues = Array.from(
        { length: fetchedQuestions.length },
        () => "0"
      );
      setRadioValues(initRadioValues);
      console.log(initRadioValues);
    }
  }, [fetchedQuestions]);

  const handlePrevPage = () => {
    window.scroll(0, 0);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (!isAllAnswered()) {
      alert("Please answer all the questions! ");
      return;
    }
    window.scroll(0, 0);
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(fetchedQuestions.length / questionsPerPage)
      )
    );
  };

  const navigate = useNavigate();
  const renderQuestions = () => {
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    isLastPage =
      currentPage === Math.ceil(fetchedQuestions.length / questionsPerPage);
    var questions = fetchedQuestions
      .slice(startIndex, endIndex)
      .map((item, index) => (
        <VARKTestQuestion
          key={startIndex + index}
          item={item}
          radioValue={radioValues[startIndex + index]}
          onRadioChange={(value) =>
            handleRadioChange(startIndex + index, value)
          }
        />
      ));
    return questions;
  };

  const isAllAnswered = () => {
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    for (var i = startIndex; i < endIndex; i++) {
      if (radioValues[i] == "0") return false;
    }
    return true;
  };
  const sendApi = () => {
    if (!isAllAnswered()) {
      alert("Please answer all the questions! ");
      return;
    }
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    const VARKResults = {
      V: 0,
      A: 0,
      R: 0,
      K: 0,
    };
    radioValues.forEach((element) => {
      if (element === "V") {
        VARKResults.V += 1;
      } else if (element === "A") {
        VARKResults.A += 1;
      } else if (element === "R") {
        VARKResults.R += 1;
      } else if (element === "K") {
        VARKResults.K += 1;
      }
    });

    const resultList = [
      ["Visual", VARKResults.V],
      ["Auditory", VARKResults.A],
      ["Read/Write", VARKResults.R],
      ["Kinesthetic", VARKResults.K],
    ];

    user["vark"] = resultList;
    axios
      .post(
        "https://branchselctor--get-results.anish98821.workers.dev/?query=vark",
        user,
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // const data=response.data;
        navigate("/vark/results", { state: { VARKResults } });
      })
      .catch((error) => {
        console.log(error);
        alert("Somthing went wrong plz try again later");
      });

    newTestTaken("VARK Test", "VarkTempResult");
  };

  return (
    <Layout>
      {userDetails != null && !loading ? (
        <div className="test-main-container">
          <TestSideBar
            profilePic={
              userDetails["photoUrl"]
                ? userDetails["photoUrl"]
                : getDefaultProfilePic()
            }
            userName={
              userDetails["displayName"] ? userDetails["displayName"] : "User"
            }
            email={
              userDetails["email"] ? userDetails["email"] : "user@mail.com"
            }
            testName={"VARK Test"}
            answeredQuestions={5}
            totalQuestions={15}
          />
          <div className="test-container">
            {currentPage == 1 && (
              <div>
                <h1 id="instruct">Instructions!</h1>
                <br />
                <div id="instructionTxt">
                  <div id="instructionContents">
                    <ul>
                      <li>
                        &nbsp;While answering the test, be honest. Answer to
                        express, not to impress.
                      </li>
                      <li>
                        &nbsp;Do not ask for suggestions with friends/check
                        online for answers while answering the questions.
                      </li>
                      <li>
                        &nbsp;Take the test 2 times to get better understanding
                        of your preferences.
                      </li>
                      <li>
                        &nbsp;Read the e-Book from the Downloads section to
                        understand the various branches and the career
                        opportunities.
                      </li>
                    </ul>
                  </div>
                </div>{" "}
              </div>
            )}
            <br />
            {fetchedQuestions && (
              <h3 id="questionHeading">
                Please answer the following questions ({currentPage}/
                {Math.ceil(fetchedQuestions.length / questionsPerPage)})
              </h3>
            )}
            <br />
            {loading ? (
              <p id="loadTxt">Loading...</p>
            ) : (
              <div>
                <div>{renderQuestions()}</div>
                <br />
                <div className="takeQuizBtns">
                  <div className="backNextBtns">
                    <button
                      id="backBtn"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Back
                    </button>
                    {!isLastPage && (
                      <button
                        id="nextBtn"
                        onClick={handleNextPage}
                        disabled={
                          currentPage ===
                          Math.ceil(fetchedQuestions.length / questionsPerPage)
                        }
                      >
                        Next
                      </button>
                    )}
                  </div>
                  <div className="getResultBtn">
                    {isLastPage && (
                      <button id="resultBtn" onClick={() => sendApi()}>
                        Get Result
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="loading-screen">Loading...</div>
      )}
    </Layout>
  );
};

export default VARKTestPage;
