


const StreamTestQuestion=({item,key,radioValue, onRadioChange})=>{
return (
<div  className = 'questionComponent' >
                                
                                <div className='questionBox'><p id='questionTxt'>{item.Question}</p></div>
                                
                                <div style={{textAlign:'left',paddingLeft:'15px'}}>
                                <div id='optionTxt' onClick={() => onRadioChange(1)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={1} checked={radioValue === 1} onChange={() => onRadioChange(1)}></input>{item.Arts}</div>
                                <div id='optionTxt' onClick={() => onRadioChange(2)}> <input type="radio" name={key}style={{marginRight:'10px'}}  value={2} checked={radioValue === 2} onChange={() => onRadioChange(2)} ></input>{item.Science} </div>
                                <div id='optionTxt' onClick={() => onRadioChange(3)}> <input type="radio" name={key}style={{marginRight:'10px'}} value={3} checked={radioValue === 3} onChange={() => onRadioChange(3)} ></input>{item.Commerce}   </div>
                                </div>
    
                                
                            
                            </div>
)
}

export default StreamTestQuestion;