const BrainTestQuestion=({item,key,radioValue, onRadioChange})=>{
    return (
    <div  className = 'questionComponent' >
                                    
            <div className='questionBox'><p id='questionTxt'>{item.question}</p></div>
            
            <div style={{textAlign:'left',paddingLeft:'15px'}}>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[0].brain_type)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[0].brain_type} checked={radioValue === item.options[0].brain_type} onChange={() => onRadioChange(item.options[0].brain_type)}></input>{item.options[0].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[1].brain_type)}> <input type="radio" name={key}style={{marginRight:'10px'}}  value={item.options[0].brain_type} checked={radioValue === item.options[1].brain_type} onChange={() => onRadioChange(item.options[1].brain_type)} ></input>{item.options[1].text} </div>
           
            </div>

            
        
        </div>
    )
    }
    
    export default BrainTestQuestion;