export function PersonalityInfocard() {
    return (
        <div className="card-container">
            <div className="main-heading">What do these traits mean?</div>
            <div className="trait">
                <h2 className="trait-heading">Openness to Experience</h2>
                <p className="trait-content">
                    Individuals high in openness tend to be imaginative, creative, and curious about the world around them.
                    They are open to new ideas, experiences, and perspectives, and often enjoy exploring different cultures, art forms, and intellectual pursuits.
                    People with high openness are often unconventional and nontraditional in their thinking and behavior.
                    They may excel in creative fields such as art, music, literature, or scientific research.
                    However, extreme openness can sometimes lead to unpredictability or a lack of focus on practical matters.
                </p>
                <p className="trait-keywords">
                    Keywords: Creative, Imaginative, Curious, Unconventional, Open-minded
                </p>
            </div>
            <div className="trait">
                <h2 className="trait-heading">Conscientiousness</h2>
                <p className="trait-content">
                    Conscientious individuals are reliable, organized, and self-disciplined, with a strong sense of duty and responsibility.
                    They set high standards for themselves and strive for achievement in their goals and tasks.
                    People high in conscientiousness are often diligent, detail-oriented, and thorough in their work.
                    They tend to be punctual, plan ahead, and adhere to deadlines and schedules.
                    However, excessive conscientiousness may lead to perfectionism or rigidity, causing stress or difficulty in adapting to change.
                </p>
                <p className="trait-keywords">
                    Keywords: Reliable, Organized, Self-disciplined, Goal-oriented, Punctual
                </p>
            </div>
            <div className="trait">
                <h2 className="trait-heading">Extraversion</h2>
                <p className="trait-content">
                    Extraverts are outgoing, sociable, and energetic individuals who thrive in social settings and enjoy interacting with others.
                    They are often characterized by assertiveness, talkativeness, and enthusiasm in their interpersonal interactions.
                    Extraverts draw energy from socializing and being around people, and they often seek out stimulating environments and activities.
                    They tend to be confident and comfortable in social situations, making friends easily and enjoying group activities.
                    However, too much extraversion can lead to impulsivity or a need for constant stimulation, while introverts may find it overwhelming.
                </p>
                <p className="trait-keywords">
                    Keywords: Outgoing, Sociable, Energetic, Assertive, Talkative
                </p>
            </div>
            <div className="trait">
                <h2 className="trait-heading">Agreeableness</h2>
                <p className="trait-content">
                    Agreeable individuals are compassionate, empathetic, and cooperative, with a genuine concern for the well-being of others.
                    They value harmony and cooperation in relationships and strive to maintain positive interpersonal connections.
                    People high in agreeableness are often considerate, supportive, and understanding in their interactions with others.
                    They are willing to compromise and avoid conflict, preferring to seek consensus and find mutually beneficial solutions.
                    However, excessive agreeableness may lead to vulnerability to manipulation or difficulty asserting one's own needs or boundaries.
                </p>
                <p className="trait-keywords">
                    Keywords: Compassionate, Empathetic, Cooperative, Considerate, Harmonious
                </p>
            </div>
            <div className="trait">
                <h2 className="trait-heading">Neuroticism</h2>
                <p className="trait-content">
                    Neuroticism refers to the tendency to experience negative emotions such as anxiety, depression, and insecurity.
                    Individuals high in neuroticism are prone to mood swings, worry, and emotional instability, often reacting strongly to stress or adversity.
                    They may ruminate on past experiences or anticipate future problems, leading to feelings of distress or dissatisfaction.
                    Neurotic individuals may have low self-esteem and a heightened sensitivity to criticism or rejection.
                    However, moderate levels of neuroticism can serve as a protective factor, alerting individuals to potential threats and motivating them to take action to address them.
                </p>
                <p className="trait-keywords">
                    Keywords: Anxious, Insecure, Emotional, Sensitive, Moody
                </p>
            </div>

        </div>
    );
}