import "./App.css";
import { Outlet } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./Components/Common/Navbar";

import HomePage from "./Components/Common/MainHome";
import TestList from "./Components/Common/TestList";
import PreTest from "./Components/Common/PreTest";

import BookingPage from "./Components/Common/BookingPage";
import AfterBooking from "./Components/Common/AfterBooking";
import ContactPage from "./Components/Common/ContactPage";
import AfterContact from "./Components/Common/AfterContact";

import EBook from "./Components/Common/EBook";
import About from "./Components/Common/About";
import Privacy from "./Components/Common/Privacy";

import EngineeringTestPage from "./Components/Tests/Enginnering/EngineeringTestPage";
import EngineeringHomePage from "./Components/Tests/Enginnering/EngineeringHomePage";
import EngineeringResultPage from "./Components/Tests/Enginnering/EngineeringResultPage";

import StreamHomePage from "./Components/Tests/Stream/StreamHomePage";
import StreamTestPage from "./Components/Tests/Stream/StreamTestPage";
import StreamResultPage from "./Components/Tests/Stream/StreamResultPage";

import BrainHomePage from "./Components/Tests/Brain/BrainHomePage";
import BrainTestPage from "./Components/Tests/Brain/BrainTestPage";
import BrainResultPage from "./Components/Tests/Brain/BrainResultPage";

import VARKHomePage from "./Components/Tests/VARK/VARKHomePage";
import VARKTestPage from "./Components/Tests/VARK/VARKTestPage";
import VARKResultPage from "./Components/Tests/VARK/VARKResultPage";

import PersonalityHomePage from "./Components/Tests/Personality/PersonalityHomePage";
import PersonalityTestPage from "./Components/Tests/Personality/PersonalityTestPage";
import PersonalityResultPage from "./Components/Tests/Personality/PersonalityResultPage";

import InterestHomePage from "./Components/Tests/Interest/InterestHomePage";
import InterestTestPage from "./Components/Tests/Interest/InterestTestPage";
import InterestResultPage from "./Components/Tests/Interest/InterestResultPage";

import StrengthHomePage from "./Components/Tests/Strength/StrengthHomePage";
import StrengthTestPage from "./Components/Tests/Strength/StrengthTestPage";
import StrengthResultPage from "./Components/Tests/Strength/StrengthResultPage";


import IQHomepage from "./Components/Tests/IQ/IOHomepage";
import IQTestPage from "./Components/Tests/IQ/IQTestPage";
import IQresults from "./Components/Tests/IQ/IQresults";

import Auth from "./Components/Common/Authentication/Auth";
import Blog from "./Components/Blog/Blog";
import PageTitle from "./Components/Common/PageTitle";
import AuthenticatedRoute from "./services/AuthenticatedRoute";

import AuthTesting from "./Components/Common/AuthPage/AuthTesting";
import { ServicesTest } from "./services/ServicesTest";

import { UserDashboard } from "./Components/UserDashboard/UserDashboard";
import TestPage from "./Components/Tests/MultipleChoiceType/TestPage/MainTestPage/TestPage";


// admin dashboard imports

import Header from "./Components/Admin/AdminDashboard/Header";
import Sidebar from "./Components/Admin/AdminDashboard/Sidebar";
import Dashboard from "./Components/Admin/AdminDashboard/Dashboard";
import AddBlog from "./Components/Admin/AdminDashboard/Pages/Blog";
import Download from "./Components/Admin/AdminDashboard/Pages/BookDownload";
import Test from "./Components/Admin/AdminDashboard/Pages/TestTaken";
import Voucher from "./Components/Admin/AdminDashboard/Pages/VoucherCode";
import { useState } from "react";
import "./Components/Admin/AdminDashboard/dashboard.css";

function App() {

  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/admin"
            element={
              <div className="a_grid-container">
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar
                  openSidebarToggle={openSidebarToggle}
                  OpenSidebar={OpenSidebar}
                />
                <Outlet />
              </div>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="/admin/test" element={<Test />}></Route>
            <Route path="/admin/bookDownload" element={<Download />}></Route>
            <Route path="/admin/voucher" element={<Voucher />}></Route>
            <Route path="/admin/blog" element={<AddBlog />}></Route>
          </Route>

          <Route
            element={
              <>
                <PageTitle />
                <Navbar />
                <Outlet />
              </>
            }
          >
            <Route exact path="/Blog" element={<Blog />}></Route>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route exact path="/Tests" element={<TestList />}></Route>

            <Route exact path="/booking" element={<BookingPage />}></Route>
            <Route
              exact
              path="/booking-confirmation"
              element={<AfterBooking />}
            ></Route>
            <Route exact path="/contactus" element={<ContactPage />}></Route>
            <Route
              exact
              path="/contact-confirmation"
              element={<AfterContact />}
            ></Route>

            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/ebook" element={<EBook />} />

            <Route exact path="testing" element={<AuthTesting />} />
            <Route exact path="/Privacy" element={<Privacy />}></Route>

            {/*//////////--------Engineering Test-----------///////*/}

            <Route element={<AuthenticatedRoute />}>
              <Route exact path="/pre-test" element={<PreTest />}></Route>

              <Route
                exact
                path="/engineering"
                element={<EngineeringHomePage />}
              ></Route>
              <Route
                exact
                path="/engineering/test"
                element={<EngineeringTestPage />}
              ></Route>
              <Route
                exact
                path="/engineering/results"
                element={<EngineeringResultPage />}
              ></Route>

              {/*//////////--------Class X Test-----------///////*/}

              <Route exact path="/ClassX" element={<StreamHomePage />}></Route>
              <Route
                exact
                path="/ClassX/test"
                element={<StreamTestPage />}
              ></Route>
              <Route
                exact
                path="/ClassX/results"
                element={<StreamResultPage />}
              ></Route>

              {/*//////////--------Brain Test-----------///////*/}

              <Route exact path="/brain" element={<BrainHomePage />}></Route>
              <Route
                exact
                path="/brain/test"
                element={<BrainTestPage />}
              ></Route>
              <Route
                exact
                path="/brain/results"
                element={<BrainResultPage />}
              ></Route>

              {/*//////////--------Vark Test-----------///////*/}

              <Route exact path="/vark" element={<VARKHomePage />}></Route>
              <Route exact path="/vark/test" element={<VARKTestPage />}></Route>
              <Route
                exact
                path="/vark/results"
                element={<VARKResultPage />}
              ></Route>

              {/*//////////--------Personality Test-----------///////*/}

              <Route
                exact
                path="/personality"
                element={<PersonalityHomePage />}
              ></Route>
              <Route
                exact
                path="/personality/test"
                element={<PersonalityTestPage />}
              ></Route>
              <Route
                exact
                path="/personality/results"
                element={<PersonalityResultPage />}
              ></Route>

              {/*//////////--------Interest Test-----------///////*/}

              <Route
                exact
                path="/interest"
                element={<InterestHomePage />}
              ></Route>
              <Route
                exact
                path="/interest/test"
                element={<InterestTestPage />}
              ></Route>
              <Route
                exact
                path="/interest/results"
                element={<InterestResultPage />}
              ></Route>

              {/*//////////--------Strength Test-----------///////*/}

              <Route
                exact
                path="/strength"
                element={<StrengthHomePage />}
              ></Route>
              <Route
                exact
                path="/strength/test"
                element={<StrengthTestPage />}
              ></Route>

              <Route
                exact
                path="/strength/results"
                element={<StrengthResultPage />}
              ></Route>

              <Route exact path="/IQ" element={<IQHomepage />}></Route>
              <Route exact path="/IQ/test" element={<IQTestPage />}></Route>
              <Route exact path="/IQ/IQresults" element={<IQresults />}></Route>

              <Route
                exact
                path="/dashboard"
                element={<UserDashboard />}
              ></Route>
            </Route>

            {/*\\\\\\\\\\--------IQ Test-------------\\\\\\\\\*/}

            <Route
              exact
              path="/login"
              element={<Auth authType="login" />}
            ></Route>
            <Route
              exact
              path="/signup"
              element={<Auth authType="signup" />}
            ></Route>

            {/* <Route
              exact
              path="/services-test"
              element={<ServicesTest></ServicesTest>}
            ></Route> */}


            <Route exact path="/IQ" element={<IQHomepage />}></Route>
            <Route exact path="/IQ/test" element={<IQTestPage />}></Route>
            <Route exact path="/IQ/IQresults" element={<IQresults />}></Route>

            <Route exact path="/dashboard" element={<UserDashboard />}></Route>
          </Route>

          {/* <Route exact path="/Admin" element={<Admin />}></Route> */}
          {/*\\\\\\\\\\--------IQ Test-------------\\\\\\\\\*/}



          {/* <Route exact path="/common-test" element={<TestPage />}></Route> */}

      

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
