import React from "react";
import { IoIosAddCircle } from "react-icons/io";
import VoucherData from "./Data/voucherCode.json";
import { MdDelete } from "react-icons/md";

function VoucherCode() {
  const data = VoucherData;

  return (
    <main id="voucher" className="a_main-container">
      <div className="main-title text-color">
        <h3>Voucher code</h3>
      </div>
      <div id="voucheradd">
        <button>
          <IoIosAddCircle />
          &nbsp; Generate Voucher Code
        </button>
      </div>
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>No</th>
              <th>Created On</th>
              <th>Voucher Code</th>
              <th>Limit</th>
              <th>Discout(%)</th>
              <th>Valid Till</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr>
                <td>{item.id}</td>
                <td>{item.date}</td>
                <td>{item.code}</td>
                <td>{item.limit}</td>
                <td>{item.discount}</td>
                <td>{item.validity}</td>
                <td>
                  <MdDelete className="action" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default VoucherCode;
