import React, { useEffect, useState } from "react";
import "./UserDashboard.css";
import arraowImg from "./../../Assets/arrow.png";
import userImg from "./../../Assets/anishImage.png";
import crownImg from "./../../Assets/Crown.png";
import classXI from "./../../Assets/classXI.png";
import graphImg from "./../../Assets/graphResultLogo.png";
import { VscDebugRestart } from "react-icons/vsc";
import logoImg from "./../../Assets/logo192.png";

import {
  getCurrentUserInfo,
  getTestHistory,
} from "./../../services/userService";
import { logout } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import {
  getRemainingTests,
  getTestInfo,
  getTestLogo,
} from "../Tests/testService";
import { toast } from "react-toastify";

export const UserDashboard = () => {

  var recommendedTests = [];
  const [remainingTests, setRemainingTests] = useState([]);

  const [userDetails, setUserDetails] = useState(null);
  const [userTests, setUserTests] = useState(null);
  var targetScrollLeft = 500;

  const navigte = useNavigate();

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  const handleClick = () => {
    var element = document.querySelector(".arrow-section");
    element.classList.add("clicked");

    const container = document.querySelector(".recommended-tests-list");
    if (container) {
      const targetScrollLeft = container.scrollLeft + 500; // Initial position + 500
      scrollToPoint(targetScrollLeft);
    }

    // Remove the clicked class after a certain duration
    setTimeout(() => {
      element.classList.remove("clicked");
    }, 500); // Adjust the duration as needed (in milliseconds)
  };

  const scrollToPoint = (targetScrollLeft) => {
    const container = document.querySelector(".recommended-tests-list");
    if (container) {
      container.scroll({
        left: targetScrollLeft,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getCurrentUserInfo();
        setUserDetails(userDetails);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    const fetchUserTests = async () => {
      try {
        const userTests = await getTestHistory();
        setUserTests(userTests);
        setRemainingTests(getRemainingTests(userTests));
        // recommendedTests = getRemainingTests(Object.keys(userTests));
        console.log(remainingTests);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
    fetchUserTests();

    // Cleanup function (optional)
    return () => {
      // Any cleanup code if needed
    };
  }, []);

  return (
    <>
      {userDetails && userTests && remainingTests ? (
        <section className="main-section">
          <div className="profile-section-dashboard">
            <div className="reamaining-nav nav-tests-remaining1">
              <h4>Only {3 - userDetails.testsTaken} more tests remaining!</h4>
            </div>
            <div className="profile-area">
              <div className="user-image-name">
                <img src={userDetails.photoUrl} alt="" className="user-image" />
                <h4 className="user-name">{userDetails.displayName}</h4>
                <p className="user-email">{userDetails.email}</p>
              </div>

              {userDetails.accountType == "free" ? (
                <div className="premuim-button">
                  <img src={crownImg} alt="" className="crown-img" />
                  <h5 className="premium-text">Buy Premium</h5>
                </div>
              ) : null}
              <div
                className="log-out"
                onClick={() => {
                  toast.success("Logged out sucessfully!");
                  logout();
                  navigte("/");
                }}
              >
                Logout
              </div>
            </div>
          </div>

          <div className="right-section">
            {userDetails.accountType == "free" ? (
              <div className="nav-tests-remaining-section">
                <div className="nav-tests-remaining">
                  <h4>
                    Only {5 - userDetails.testsTaken} more tests remaining!
                  </h4>
                </div>
              </div>
            ) : null}

            <div className="recommended-tests-section">
              <h3 className="recommended-tests-text">Remaining Tests</h3>
              <div className="recommended-tests">
                <div className="recommended-tests-list">
                  {remainingTests.map((test) => {
                    return (
                      <div
                        className="testBorder"
                        onClick={() => {
                          navigte(getTestInfo(test["name"])["testPage"]);
                        }}
                      >
                        <div className="test">
                          {
                            <img
                              src={test.logo}
                              alt="alternate_text"
                              title="tooltip_text"
                              className="remaining-tests-images"
                            ></img>
                          }
                          <h4 className="test-text">{test["name"]}</h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div onClick={handleClick} className="arrow-section">
                  <img
                    src={arraowImg}
                    alt="alternate_text"
                    title="tooltip_text"
                    className="arrow-img"
                  ></img>
                </div>
              </div>
            </div>

            <div className="test-history-section">
              <h3 className="test-history-text recommended-tests-text">
                Test History
              </h3>

              {Object.values(userTests).length == 0 && (
                <div className="test-history-list">
                  <div className="test-history-card">
                    <div className="history-image-text-section-new">
                      <div className="history-test-text-section-new">
                        <h3 className="not-taken-test-text">
                          You have not taken any tests yet!
                        </h3>
                      </div>
                    </div>
                  </div>

                  {/* <div className="test-history-card"></div> */}
                </div>
              )}

              <div className="test-history-list">
                {userTests.map((test) => {
                  return (
                    <div className="test-history-card">
                      <div className="history-image-text-section">
                        <img
                          src={getTestLogo(test["test-name"])}
                          alt="alternate_text"
                          title="tooltip_text"
                          className="history-test-img"
                        />
                        <div className="history-test-text-section">
                          <h3 className="history-test-header">
                            {test["test-name"]}
                          </h3>
                          <p className="history-test-date">
                            {/* {test["time-taken"] &&
                              formatDate(String(test["time-taken"]))} */}
                            Completed
                          </p>
                        </div>
                      </div>
                      <div className="history-buttons-section">
                        <div
                          className="history-buttons-test"
                          onClick={() => {
                            navigte(getTestInfo(test["test-name"])["testPage"]);
                          }}
                        >
                          <div className="history-buttons-img-sec">
                            <VscDebugRestart className="restart-icon" />
                          </div>
                          <p className="history-test-text">Retake Test</p>
                        </div>
                        <div className="history-buttons-test">
                          <div
                            className="history-buttons-img-sec"
                          >
                            <img
                              src={graphImg}
                              alt=""
                              className="retake-test-img"
                            />
                          </div>

                          <p className="history-test-text">View Results</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="test-history-card"></div> */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <h1 className="loading">Loading</h1>
      )}
    </>
  );
};
