export function StrengthInfocard() {
  return (
    <div className="card-container">
      <div className="main-heading">
        What does this mean to you?
        <br /> Continue reading to know more!{" "}
      </div>

      <div className="trait">
        <h2 className="trait-heading">Achiever</h2>
        <p className="trait-content">
          Achievers are driven by setting and attaining goals, finding
          fulfillment in accomplishments. Achievers thrive in structured
          environments where they can set clear objectives and work diligently
          towards achieving them, often serving as catalysts for progress and
          innovation. Their relentless pursuit of excellence and commitment to
          success make them invaluable assets in leadership roles and
          competitive industries.
        </p>
        <p className="trait-keywords">
          Keywords: Goal-oriented, ambitious, hardworking, results-driven,
          focused.
        </p>
        <p className="trait-professions">
          Professions: Entrepreneur, project manager, salesperson, athlete,
          lawyer.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Adaptability</h2>
        <p className="trait-content">
          Adaptability involves effortlessly adjusting to new situations,
          embracing change as an opportunity for growth. Individuals with
          adaptability excel in fast-paced environments where they can quickly
          assess challenges and pivot their strategies to thrive in dynamic
          circumstances. Their ability to remain composed and adapt to evolving
          situations makes them indispensable in industries characterized by
          constant change and innovation.
        </p>
        <p className="trait-keywords">
          Keywords: Flexible, versatile, resourceful, resilient, open-minded.
        </p>
        <p className="trait-professions">
          Professions: Consultant, event planner, emergency responder,
          journalist, actor.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Communication</h2>
        <p className="trait-content">
          Communication entails effectively conveying ideas, fostering
          understanding through verbal and non-verbal means. Skilled
          communicators excel in building rapport and establishing trust with
          diverse audiences, effectively conveying complex concepts in a clear
          and engaging manner. Their adeptness at interpersonal communication
          and negotiation makes them valuable assets in roles that require
          strong leadership and collaboration.
        </p>
        <p className="trait-keywords">
          Keywords: Articulate, persuasive, listener, storyteller, diplomatic.
        </p>
        <p className="trait-professions">
          Professions: Public relations specialist, teacher, journalist,
          counselor, marketer.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Developer</h2>
        <p className="trait-content">
          Developers excel in nurturing others' potential, providing support and
          guidance for growth. Developers possess a natural talent for
          recognizing and fostering the strengths of others, empowering
          individuals to reach their full potential through encouragement and
          mentorship. Their ability to inspire and motivate others fosters a
          positive and productive work environment, driving individual and
          organizational success.
        </p>
        <p className="trait-keywords">
          Keywords: Mentor, coach, supportive, encouraging, empowering.
        </p>
        <p className="trait-professions">
          Professions: Trainer, human resources manager, counselor, therapist,
          teacher.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Empathy</h2>
        <p className="trait-content">
          Empathy involves understanding others' feelings, fostering compassion
          and support. Empathetic individuals possess a deep understanding of
          human emotions, demonstrating compassion and support in their
          interactions with others. Their ability to connect on an emotional
          level and provide genuine support makes them invaluable in roles that
          require empathy and understanding, such as counseling and healthcare.
        </p>
        <p className="trait-keywords">
          Keywords: Compassionate, sensitive, intuitive, empathetic, supportive.
        </p>
        <p className="trait-professions">
          Professions: Social worker, psychologist, nurse, counselor, teacher.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Ideation</h2>
        <p className="trait-content">
          Ideation involves generating innovative ideas and thinking creatively.
          Individuals with ideation possess a natural curiosity and creativity,
          constantly seeking new solutions and pushing the boundaries of
          conventional thinking. Their ability to think outside the box and
          generate original ideas drives innovation and problem-solving, making
          them indispensable in industries that value creativity and
          forward-thinking.
        </p>
        <p className="trait-keywords">
          Keywords: Creative, imaginative, inventive, visionary, curious.
        </p>
        <p className="trait-professions">
          Professions: Inventor, entrepreneur, designer, writer, researcher.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Maximizer</h2>
        <p className="trait-content">
          Maximizers enhance strengths and strive for excellence. Maximizers
          excel in refining and optimizing existing systems and processes,
          striving for excellence in all endeavors. Their keen attention to
          detail and commitment to continuous improvement make them valuable
          assets in roles that require quality control and performance
          optimization.
        </p>
        <p className="trait-keywords">
          Keywords: Optimizer, perfectionist, enhancer, discerning,
          excellence-driven.
        </p>
        <p className="trait-professions">
          Professions: Quality assurance specialist, brand manager, editor,
          coach, consultant.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Positivity</h2>
        <p className="trait-content">
          Positivity radiates optimism and resilience, inspiring others with
          enthusiasm. Individuals with positivity uplift those around them,
          fostering a supportive and encouraging atmosphere in both personal and
          professional settings. Their ability to maintain a positive outlook in
          challenging situations and inspire others makes them invaluable in
          roles that require motivation and morale-building.
        </p>
        <p className="trait-keywords">
          Keywords: Upbeat, cheerful, hopeful, energetic, resilient.
        </p>
        <p className="trait-professions">
          Professions: Motivational speaker, counselor, event planner, customer
          service representative, coach.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Strategic</h2>
        <p className="trait-content">
          Strategists plan ahead and foresee potential outcomes to achieve
          long-term goals. Strategic thinkers possess the foresight and
          analytical skills to anticipate obstacles and develop effective
          strategies to overcome them. Their ability to analyze complex problems
          and devise innovative solutions makes them invaluable in roles that
          require strategic planning and decision-making.
        </p>
        <p className="trait-keywords">
          Keywords: Planner, analytical, forward-thinking, tactical, problem
          solver.
        </p>
        <p className="trait-professions">
          Professions: Strategist, business analyst, management consultant,
          military strategist, financial planner.
        </p>
      </div>

      <div className="trait">
        <h2 className="trait-heading">Woo (Winning Others Over)</h2>
        <p className="trait-content">
          Wooers excel in building relationships and persuading others with
          charm and charisma. Individuals with woo possess a natural ability to
          connect with others and build rapport, leveraging their charm and
          charisma to influence and persuade. Their adeptness at networking and
          interpersonal communication makes them invaluable in roles that
          require relationship-building and influencing others.
        </p>
        <p className="trait-keywords">
          Keywords: Influencer, charismatic, persuasive, connector, sociable.
        </p>
        <p className="trait-professions">
          Professions: Salesperson, politician, public relations specialist,
          recruiter, event planner.
        </p>
      </div>
    </div>
  );
}
