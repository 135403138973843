import React from 'react';
import MissonSVG from '../../SVGs/MissionSVG'
import QuizSVG from '../../SVGs/QuizSVG'
import { Link } from 'react-router-dom';
import Layout from './Layout';
import ceoImg from '../../Logos/Panama(CEO).jpeg';
import '../../Styles/AboutPage.css';

export default function About() {
  return (
    <Layout>
      <div className='abtSec'>
        <div className='aboutSection'>
          <h1 id='mainAbtHead'>Empowering Students to Find Their Ideal Career Path</h1>
          <br />
          <p id='mainAboutTxt'>
            At BranchSelector, we understand that choosing a career path can be a daunting task, especially after completing your 12th grade. With so many options available, it can be overwhelming to decide which one is the right fit for you.
            That's why we've created BranchSelector, a comprehensive online resource designed to help you navigate the world of career choices.
            Our website provides you with the tools and information you need to make an informed decision about your future.
            <br />
          </p>

          <div className='highlightSection'>
            <div>
              <h3 className='aboutHeadings'>How We Can Help?</h3>
              <p className='aboutTxt'>
                <br />
                <MissonSVG />
                <br />
                <br />
                Our unique quiz helps you identify your interests and preferences by asking you questions about your values, skills, and personality.
                Based on your responses, we will provide you with a list of potential career paths that are a good match for you.
                We also have a team of experienced career counselors who can provide you with personalized guidance and support.
                Whether you're just starting to explore your options or you're ready to make a decision, our counselors can help you every step of the way.
              </p>
            </div>
            <div className='svgAnimation'>
            </div>
          </div>

          <div className="sponsorSection">
            <h3 className="aboutHeadings">Message from our sponsor and pillar of support</h3> <br />
            <img src={ceoImg} alt="Branch Selector Logo" height={180} width={260} />
            <br />
            <br />
            <br/>
            <p className="sponsorMessage">Dear Students, <br />
              I am writing to extend my heartfelt congratulations to you, as you embark on the exciting journey of your engineering careers. It is with great pleasure that Panama Corporation sponsors the innovative and invaluable project, www.branchselector.com, which aims to assist students like you in making informed decisions about your academic and professional paths. <br /><br/>
              The students of today hold the key to shaping India into a developed nation by embracing innovation, education, and social responsibility. Through a commitment to acquiring cutting-edge skills and knowledge, fostering an entrepreneurial spirit, and engaging in community development initiatives, students can contribute to the nation's growth. By actively participating in transformative technologies, sustainable practices, and ethical leadership, students can drive positive change and lay the foundation for a prosperous and inclusive India. It is their collective dedication to excellence, social equity, and environmental stewardship that will propel India towards becoming a developed and globally influential nation. <br/><br />
              As you navigate the challenges and opportunities ahead, always remember that your success is of utmost importance to us. If you ever have genuine requests, inquiries, or simply wish to connect, please feel free to approach us at info@panamacorporationltd.com. We are here to support you and are excited to witness the incredible contributions you will make to the field of engineering. <br /><br/>
              Wishing you the very best in all your endeavors. <br />
              <br />
              <div id='sponsorTxt'>With respectful regards, <br />
                <span style={{fontWeight : 'bold'}}>Vivek Raj</span> <br />
                CEO <br />
                Panama Corporation</div>
            </p>
          </div>

          <div className='contactSection'>
            <h3 className='aboutHeadings'>Contact Us</h3>
            <p className='aboutTxt'>
              <QuizSVG />
              <br />
              If you have any questions or would like to learn more about our services, please contact us today. We are here to help you find your ideal career path.
            </p>
            <Link to={'/contactus'}>
              <button id='contactBtn'>Contact us</button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
