const VARKTestQuestion=({item,key,radioValue, onRadioChange})=>{
    return (
    <div  className = 'questionComponent' >
                                    
            <div className='questionBox'><p id='questionTxt'>{item.question}</p></div>
            
            <div style={{textAlign:'left',paddingLeft:'15px'}}>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[0].style)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[0].style} checked={radioValue === item.options[0].style} onChange={() => onRadioChange(item.options[0].style)}></input>{item.options[0].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[1].style)}> <input type="radio" name={key}style={{marginRight:'10px'}}  value={item.options[1].style} checked={radioValue === item.options[1].style} onChange={() => onRadioChange(item.options[1].style)} ></input>{item.options[1].text} </div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[2].style)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[2].style} checked={radioValue === item.options[2].style} onChange={() => onRadioChange(item.options[2].style)}></input>{item.options[2].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[3].style)} > <input type="radio" name={key}style={{marginRight:'10px'}}  value={item.options[3].style} checked={radioValue === item.options[3].style} onChange={() => onRadioChange(item.options[3].style)} ></input>{item.options[3].text} </div>
           
            </div>

            
        
        </div>
    )
    }
    
    export default VARKTestQuestion;