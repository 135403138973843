import React from 'react'
import '../../Styles/BookingPageStyle.css'
import OfferSticker from '../../SVGs/OfferSticker';
import Layout from './Layout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";


export default function BookingPage() {
  const inputRef = useRef(null);

  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const handleCaptchaChange = () => {
    setIsCaptchaChecked(true);
  };
  const recaptchaRef = React.createRef();


  useEffect(
    () => { inputRef.current.focus() }
    , [])
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    phone: null,
    email: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (!isCaptchaChecked) {
      alert('Please complete the reCAPTCHA challenge.');
      return;
    }
    console.log(event.target.checkValidity())
    console.log("stuff")
    try {

      const response = await fetch('https://branchselctor--book-appointment.anish98821.workers.dev/', {
        method: 'POST',
        body: JSON.stringify(formData),
      });



      if (response.ok) {
        console.log(response.statusText)
        console.log('Form submitted successfully');
        navigate('/booking-confirmation')
        // Optionally, you can perform further actions upon successful submission
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  return (
    <Layout>
      <div className="container">
        <h2 id="bookingHeading">Book an Appointment</h2>
        <form className='formContents' onSubmit={handleSubmit}>

          <input type="text" name="name" value={formData.name} onChange={handleChange} required placeholder='Name' ref={inputRef} />

          <input type="tel" name="phone" pattern="[0-9]{10}" value={formData.phone} onChange={handleChange} required placeholder='Phone Number' />

          <input type="email" name="email" value={formData.email} onChange={handleChange} required placeholder='Email' />

          <div className="price">
            Price:
            <div className="new-price"> ₹1500</div>
            <span className="old-price">₹2000</span>

            <div className='offerSticker'>
              <OfferSticker />
            </div>
          </div>

          <ReCAPTCHA
            style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}
            ref={recaptchaRef}
            sitekey="6LckeGUpAAAAANUyCSTYSsbpdtmOoUmohV_xsE3L"
            onChange={handleCaptchaChange}
          />

          <button type="submit" className='bookingSubmitBtn'>Book Appointment</button>
          <br></br>
          <h5 id='bookingNote'>We will reach out to you on your provided contact info to confirm booking</h5>
        </form>
      </div>

    </Layout>
  );
}
