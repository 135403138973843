
import { useLocation, useNavigate } from 'react-router-dom';
import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';
import Layout from '../../Common/Layout';



const StreamResultPage = (props) => {
    const { state } = useLocation();
    const chartRef = useRef(null);
    const charInstance = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (charInstance.current) {
            charInstance.current.destroy();
        }
        const myChartRef = chartRef.current.getContext('2d')

        const barColors = [
            '#4ea8de',
            '#ffeb3b',
            '#8bc34a',
            'rgba(75, 192, 192, 0.2)'
        ];
        charInstance.current= new  Chart(myChartRef,{
            type:'bar',
            data:{
                labels :["Arts","Science","Commerce"],
                datasets:[
                    {
                        label: 'Streams',
                        data: [state.stream.Arts, state.stream.Science, state.stream.Commerce,],
                        backgroundColor: barColors
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            color: 'black',
                        },
                    },
                    y: {
                        display: true,
                        ticks: {
                            color: 'black',
                        },
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,


            }
        })
        // Chart.canvas.parentNode.style.height = '128px';
        // Chart.canvas.parentNode.style.width = '128px';
        return () => {
            if (charInstance.current) {
                charInstance.current.destroy()
            }
        }
    }, []);

    function bookAppointment() {
        navigate('/booking')
    }




    return (<Layout>
        {/* hello from result */}
        {/* {console.log(state.data.data)}
   <div style={{display:'flex',justifyContent:'center'}}> <canvas id="myChart" style={{  maxWidth: '80vw', maxHeight:'60vh' }} ref={chartRef} ></canvas></div> */}


        <div className='resultsContainer'>
            <h1 className='resultsHeading'>Your results are ready!🎉</h1>
            <div className='resultsText'> Based on the questions you answered, here is the breakdown of your interests across the three options. Feel free to explore further details!
            </div>
            <div className="chartContainer">
                <div > <canvas id="myChart" className='ResultsBarGraph' ref={chartRef} ></canvas></div>

            </div>
            {/* <div className='resultsBranches'>4.{" "+recivedData[3][0]}<br/>5.{" "+recivedData[4][0]}</div> */}
        </div>

        <div className='bookingContainer'>
            <div className='counsellingHeading'>
                Still confused? </div>
            <div className='counsellingText'>Book a counselling session for one on one personal guidence!</div>

            <button className='bookAppointment' onClick={() => bookAppointment()} >Book Appointment</button>
        </div>
    </Layout>)
}

export default StreamResultPage;