import React from 'react';
import RangeSlider from './RangeSlider';

export default function EngineeringTestQuestions(props) {
  return (
    <div className = 'questionComponent'>
      <div className='questionBox'>
        <p id='questionTxt'>{props.question}</p>
      </div>
      <RangeSlider value={props.sliderValue} onSliderChange={props.onSliderChange} />
    </div>
  );
}
