import React, { useState } from 'react';
import { registerWithEmailAndPassword , signInWithGoogle } from '../../../../services/authService.js';
import bslogo from './../../../../Assets/logo192.png';
import './signup.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [rememberMe, setRememberMe] = useState(true)

    const location = useLocation();
    const navigate = useNavigate();
    const intendedDestination = location.state?.intendedDestination || '/';


    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);

    const showPassword = () => {
        const checkbox = document.getElementById('showpasscheckbox');
        const passwordInput = document.getElementById('password-signup');
    
        // Check if the checkbox is checked
        if (checkbox.checked) {
            // Change the type of the input field to 'text' to show the password
            passwordInput.type = 'text';
        } else {
            // Change the type of the input field to 'password' to hide the password
            passwordInput.type = 'password';
        }
    };
    
    const toggleRememberMe = () => {
        setRememberMe(!rememberMe)
    };

    const handleSignUp = async () => {
        try {
            console.log("Validating the details...");
            // Perform form validation
            const errorMessage = validateSignUpForm(); // Assuming validateSignUpForm is defined elsewhere
            
            // If there are validation errors, display the error message
            if (errorMessage) {
                toast.error(errorMessage); // Display error toast
                console.error(errorMessage);
                return; // Exit the function early
            }
    
            // If validation passes, proceed with user registration
            const result = await registerWithEmailAndPassword(name, email, password);
            navigate(intendedDestination);
            console.log("User registration successful:", result);
            toast.success("Registration successful!"); // Display success toast
        } catch (error) {
            // Handle any unexpected errors
            console.error("Error during user registration:", error);
            // Display error toast for unexpected errors
            toast.error("An error occurred during registration. Please try again later.");
        }
    };

    const handleGoogleSignIn = async () => {
        let result = await signInWithGoogle({'rememberMe':rememberMe});
        toast.success("Log In successful!");
        navigate(intendedDestination);
        console.log('navigation')
        console.log('result',result);
    };

    const validateSignUpForm = () => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Error message
        let errorMessage = '';
    
        // Check email validation
        if (!emailRegex.test(email)) {
            errorMessage = 'Invalid email address.';
        }
    
        // Check password length
        else if (password.length < 8) {
            errorMessage = 'Password must be at least 8 characters long.';
        }
    
        // Return error message
        return errorMessage;
    };
  

    return (
        <div className='signup-container'>
            <div id='logo-contaniner'>
                    <img src={bslogo} alt="bslogo" id='logo-image' height={50} width={50}/>
                    <p id='logo-title'>BranchSelector</p>
            </div>
        <div className='form-content'>
            <div className="form-content-login">
                <span>Register</span>
              
            </div>
            
            <div className='inputs'>
                <label id="email-lable" for="name">Full Name</label>
                <input type="text" id="" name="name" className="form-input" onChange={handleNameChange}/>
            </div>

            <div className='inputs'>
                <label id="email-lable" for="username">EMAIL ADDRESS</label>
                <input type="text" id="" name="username" className="form-input"  onChange={handleEmailChange}/>
            </div>
            
            <div className='inputs'>
                <label id="email-lable" for="password">PASSWORD</label>
                <input type="password" id="password-signup" name="pasword" className="form-input" onChange={handlePasswordChange}/>
            </div>

        <div className='checks'>
        <div id='remember'>
            <input type="checkbox" name="rememberme" id='rememberMe' onChange={toggleRememberMe}></input>
            <label for="rememberme">Remember Me</label>
        </div>
        <div id='showpass'>
            <input type="checkbox" name="showpass" id="showpasscheckbox" onChange={showPassword}/>
            <label for="showpass">Show Password</label>
        </div>
        </div>

        <button className='btn-main'  id='signin-btn' type='submit' onClick={handleSignUp}>Sign Up</button>

        </div>
        <span>or</span>
        <button className='login-with-google' onClick={handleGoogleSignIn}>
                
                <img className = 'image-holder' src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg' alt='no imag'/>
          
                <span className='login-with-google-text'>
                Sign in with Google
                </span>
         
        </button>
        <button type='submit' id='login-btn' hidden>Login</button>
        </div>
    );
};

export default SignUp;
