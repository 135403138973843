import React from 'react'
import { Link } from 'react-router-dom';
import "../../Styles/FooterStyle.css";
import { colors } from '@mui/material';

export default function Footer() {
  return (
    <div className='footer'>
      <p id="footerTxt">
        <img src={process.env.PUBLIC_URL + '/logo512.png'} alt="Logo" height="2" className="d-inline-block align-top ml-2" />

        BranchSelector &copy; 2011-2024. Developed by
        <span><Link id='footerLink' to='http://www.torsecure.com' target='_blank'>TORSECURE</Link></span>
        <br />
        <span class='priv_link'>
          <Link to='/Privacy' style={{ textDecoration: "none" }}>Privacy Policy</Link></span>
      </p>
    </div>
  )
}
