import React, { useState, useEffect } from 'react';

/* This component is used to display the results of the left right brain test*/

export default function BrainSVG(props) {
    const { fillPerc } = props;
    const [animate, setAnimate] = useState(false);


    const initialWidth = fillPerc > 50 ? '0%' : '100%'; // used set the direction from from which the fill animation will start

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 100);
    }, []);

    const remainingClipStyle = {
        width: animate ? `${fillPerc}%` : initialWidth,
        transition: 'width 2s ease-in-out'
    };

    return (
        <svg width="237" height="269" viewBox="0 0 237 269" fill="none" xmlns="http://www.w3.org/2000/svg">
            <clipPath id="progressClip">
                <rect x="0" y="0" width="100%" height="269" />
            </clipPath>

            <clipPath id="remainingClip">
                <rect x="0" y="0" style={remainingClipStyle} height="269" />
            </clipPath>

            <path fill="blue" d="M236.125 129.852C236.125 119.932 232.251 110.531 225.404 103.617C226.488 100.068 227.037 96.3973 227.037 92.6685C227.037 74.7309 214.454 59.75 197.843 56.4557C199.113 53.219 199.771 49.7493 199.771 46.189C199.771 34.8265 193.021 25.0484 183.408 20.8222C167.392 7.77544 149.876 0 136.435 0C128.964 0 122.338 3.75787 118.258 9.519C114.178 3.75787 107.552 0 100.081 0C86.6401 0 69.1236 7.77544 53.1079 20.8222C43.495 25.0484 36.7451 34.8265 36.7451 46.189C36.7451 49.7493 37.4034 53.219 38.673 56.4557C22.0625 59.75 9.47942 74.7309 9.47942 92.6685C9.47942 96.3973 10.0276 100.067 11.1125 103.617C4.26486 110.531 0.390869 119.932 0.390869 129.852C0.390869 137.607 2.74764 144.807 6.76024 150.753C2.5903 156.919 0.390869 164.111 0.390869 171.684C0.390869 185.098 7.54072 197.388 18.8611 203.868C18.6662 205.525 18.568 207.197 18.568 208.867C18.568 226.962 30.1076 242.959 46.8504 248.411C54.1332 261.16 67.399 269 81.9038 269C97.8059 269 111.594 259.599 118.258 245.95C124.922 259.599 138.71 269 154.612 269C169.117 269 182.383 261.16 189.666 248.411C206.408 242.959 217.948 226.961 217.948 208.867C217.948 207.197 217.85 205.525 217.655 203.868C228.975 197.388 236.125 185.098 236.125 171.684C236.125 164.111 233.926 156.919 229.756 150.753C233.768 144.807 236.125 137.607 236.125 129.852Z" clipPath="url(#progressClip)" />
          
            <path fill="red" d="M236.125 129.852C236.125 119.932 232.251 110.531 225.404 103.617C226.488 100.068 227.037 96.3973 227.037 92.6685C227.037 74.7309 214.454 59.75 197.843 56.4557C199.113 53.219 199.771 49.7493 199.771 46.189C199.771 34.8265 193.021 25.0484 183.408 20.8222C167.392 7.77544 149.876 0 136.435 0C128.964 0 122.338 3.75787 118.258 9.519C114.178 3.75787 107.552 0 100.081 0C86.6401 0 69.1236 7.77544 53.1079 20.8222C43.495 25.0484 36.7451 34.8265 36.7451 46.189C36.7451 49.7493 37.4034 53.219 38.673 56.4557C22.0625 59.75 9.47942 74.7309 9.47942 92.6685C9.47942 96.3973 10.0276 100.067 11.1125 103.617C4.26486 110.531 0.390869 119.932 0.390869 129.852C0.390869 137.607 2.74764 144.807 6.76024 150.753C2.5903 156.919 0.390869 164.111 0.390869 171.684C0.390869 185.098 7.54072 197.388 18.8611 203.868C18.6662 205.525 18.568 207.197 18.568 208.867C18.568 226.962 30.1076 242.959 46.8504 248.411C54.1332 261.16 67.399 269 81.9038 269C97.8059 269 111.594 259.599 118.258 245.95C124.922 259.599 138.71 269 154.612 269C169.117 269 182.383 261.16 189.666 248.411C206.408 242.959 217.948 226.961 217.948 208.867C217.948 207.197 217.85 205.525 217.655 203.868C228.975 197.388 236.125 185.098 236.125 171.684C236.125 164.111 233.926 156.919 229.756 150.753C233.768 144.807 236.125 137.607 236.125 129.852Z" clipPath="url(#remainingClip)" />
        <text x="75%" y="45%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="17">   
            <tspan>{(100-fillPerc).toFixed(2)}%</tspan>
            <tspan x="75%" dy="1.2em">{"Right Brain"}</tspan>
        </text>

        <text x="30%" y="45%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="17">   
            <tspan>{(fillPerc).toFixed(2)}%</tspan>
            <tspan x="28%" dy="1.2em">{ "Left Brain"}</tspan>
        </text>

        </svg>
    );
}
