import React from 'react'
import Login from './login/Login';
import SignUp from './signup/SignUp';
import './auth.css';
import { useNavigate } from 'react-router-dom';



const Auth = ({authType}) => {

  const navigate = useNavigate();

  return (
    <section className='auth-main-container'>
   
      <div className='auth-container'>
      {authType === 'signup' ? <SignUp /> :
      <div className="toggle-signup-container">
          {/* Content for toggling to signup */}
          <h2>Don't have an Account?</h2>
          <span>Create a free account today to access our wide range of tests designed to help you identify 
            your traits and strengths!
          </span>
          <button id='register-toggle-btn' className='auth-btn' onClick={()=>{navigate('/signup')}}>
            Register Now
          </button>

        </div>}
        {authType === 'login' ? <Login /> : 
        <div className='toggle-login-container'>
          {/* Content for toggling to login */}
          <h2>Already have an account?</h2>
   
          <span>
          Login to access your test history, book appointments, download ebooks and much more!
          </span>
          <button id='login-toggle-btn' className='auth-btn' onClick={()=>{navigate('/login')}}>
            Login
          </button>
        </div>}
      </div>
    </section>
  );
}

export default Auth;
