import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import mainImage from '../../Assets/Designer.png';
import '../../Styles/MainHomePage.css';


export default function HomePage() {
  const [typedIntro, setTypedIntro] = useState('');
  const [typedGrade, setTypedGrade] = useState('');
  const [typedBrand, setTypedBrand] = useState('');
  const introTexts = 'Welcome to ';
  const brandText = 'Branch Selector';
  const gradeTexts = ' A first of its kind website to help students to understand their true potential!';


  useEffect(() => {
    const typeIntro = async (introText, setTypedIntro) => {
      for (let i = 0; i < introText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        setTypedIntro(introText.substring(0, i + 1));
      }
    };

    const typedBrand = async (brandText, setTypedBrand) => {
      for (let i = 0; i < brandText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        setTypedBrand(brandText.substring(0, i + 1));
      }
    };

    const typeGrade = async (gradeText, setTypedGrade) => {
      for (let i = 0; i < gradeText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 50));
        setTypedGrade(gradeText.substring(0, i + 1));
      }
    };

    typedBrand(brandText, setTypedBrand);
    typeIntro(introTexts, setTypedIntro);
    typeGrade(gradeTexts, setTypedGrade);
  }, []);

  return (
    <Layout>
      <div className="firstContainer">
        <div id='subContainerOne'>
          <h1 id="intro">{typedIntro}
            <span style={{ color: '#1C7CFC' }}>{typedBrand}</span></h1>
          <h2 id="grade">{typedGrade}</h2>
          <p id="desc">
            Take our AI enabled tests to find out
            <br /> your true potential.
          </p>
          <Link id='takeTestsLink' to={'/Tests'}>
            <button id="takeQuizBtn">Take Test</button>
          </Link>
        </div>
        <div id='subContainerTwo'>
          <img id='mainImg' src={mainImage} alt="" width={410} height={410}/>
        </div>
      </div>

    </Layout>
  );
}