import React, { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import Layout from '../../Common/Layout';
import { isSignedIn } from '../../../services/authService';

export default function EngineeringHomePage() {
  const [typedIntro, setTypedIntro] = useState('');
  const [typedGrade, setTypedGrade] = useState('');
  

  const introTexts = 'Confused about which branch to choose';
  const gradeTexts = 'in engineering?';

  const navigate = useNavigate();

  useEffect(() => {
    const typeIntro = async (introText,setTypedIntro) => {
      for (let i = 0; i < introText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100)); 
        setTypedIntro(introText.substring(0, i + 1)); 
      }
    };

    const typeGrade = async (gradeText,setTypedGrade) => {
      for (let i = 0; i < gradeText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        setTypedGrade(gradeText.substring(0, i + 1));
      }
    };

    typeIntro(introTexts,setTypedIntro);
    typeGrade(gradeTexts,setTypedGrade);
  }, []);

  return (
    <Layout>

      <div className="firstContainer">

        <h1 id="intro">{typedIntro}</h1>
        <h1 id="grade">{typedGrade}</h1>

        <p id="desc">
          Take our AI enabled test to find out
          <br /> the right branch for you.
        </p>
        <button onClick={()=>{console.log(isSignedIn())}}>adsf</button>
      <button id="takeQuizBtn" onClick={()=>navigate('/pre-test',{state:"EngineeringTest"})}>Take Test</button>
      </div>

    </Layout>
  );
}
