import { useLocation, useNavigate } from 'react-router-dom';
import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';
import Layout from '../../Common/Layout';
import { PersonalityInfocard } from './Infocard/PersonalityInfocard';


const PersonalityResultPage = (props) => {
    const { state } = useLocation();
    const chartRef = useRef(null);
    const charInstance = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(state)
        window.scrollTo(0, 0);
        if (charInstance.current) {
            charInstance.current.destroy();
        }

        const myChartRef = chartRef.current.getContext('2d');

        const VColor = 'rgba(143, 49, 58, 0.90)';
        const RColor = 'rgba(106, 136, 255, 0.90)';
        const KColor = 'rgba(0, 125, 89, 0.90)';
        const AColor = 'rgba(247, 203, 66, 0.90)';

        charInstance.current = new Chart(myChartRef, {
            type: 'bar',
            data: {
                labels: ["Openness to Experience", "Conscientiousness", "Extraversion", "Agreeableness"],
                datasets: [
                    {
                        label: "Learning Styles",
                        data: [state.PersonalityResults["Openness to Experience"], state.PersonalityResults["Conscientiousness"], state.PersonalityResults["Extraversion"], state.PersonalityResults["Agreeableness"]],
                        backgroundColor: [VColor, AColor, RColor, KColor],
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                },
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            color: 'black',
                        },
                    },
                    y: {
                        display: true,
                        ticks: {
                            color: 'black',
                        },
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });

        return () => {
            if (charInstance.current) {
                charInstance.current.destroy();
            }
        };
    }, [state.PersonalityResults.V, state.PersonalityResults.A, state.PersonalityResults.R, state.PersonalityResults.K]);

    function bookAppointment() {
        navigate('/booking');
    }

    return (
        <Layout>
            <div className='resultsContainer'>
                <h1 className='resultsHeading'>Your results are ready!🎉</h1>
                <div className='resultsText'>
                    Based on the questions you answered, here is the breakdown of your 4 main personality traits.
                    Feel free to explore further details!
                </div>
                <div className="chartContainer">
                    <div>
                        <canvas id="myChart" ref={chartRef} className='ResultsBarGraph'></canvas>
                    </div>
                </div>
            </div>
            <br /><br />
            <PersonalityInfocard />
            <div className='bookingContainer'>
                <div className='counsellingHeading'>
                    Still confused? </div>
                <div className='counsellingText'>Book a counseling session for one-on-one personal guidance!
                </div>
                <button className='bookAppointment' onClick={() => bookAppointment()}>Book Appointment</button>
            </div>
        </Layout>
    );
};

export default PersonalityResultPage