const PersonalityTestQuestion=({item,key,radioValue, onRadioChange})=>{
    return (
    <div  className = 'questionComponent' >
                                    
            <div className='questionBox'><p id='questionTxt'>{item.question}</p></div>
            
            <div style={{textAlign:'left',paddingLeft:'15px'}}>
                
            <div id='optionTxt' onClick={() => onRadioChange(item.options[0])}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[0]} checked={radioValue === item.options[0]} onChange={() => onRadioChange(item.options[0])}></input>{item.options[0].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[1])}> <input type="radio" name={key}style={{marginRight:'10px'}}  value={item.options[1]} checked={radioValue === item.options[1]} onChange={() => onRadioChange(item.options[1])} ></input>{item.options[1].text} </div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[2])}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[2]} checked={radioValue === item.options[2]} onChange={() => onRadioChange(item.options[2])}></input>{item.options[2].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[3])} > <input type="radio" name={key}style={{marginRight:'10px'}}  value={item.options[3]} checked={radioValue === item.options[3]} onChange={() => onRadioChange(item.options[3])} ></input>{item.options[3].text} </div>
           
            </div>

            
        
        </div>
    )
    }
    
    export default PersonalityTestQuestion;