import React from 'react'
import Layout from './Layout'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import '../../Styles/ContactPage.css';

export default function ContactPage() {
  const inputRef = useRef(null);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const handleCaptchaChange = () => {
    setIsCaptchaChecked(true);
  };
  const recaptchaRef = React.createRef();

  useEffect(
    () => { inputRef.current.focus() }
    , [])
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    phone: null,
    email: '',
    college: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    if (!isCaptchaChecked) {
      alert('Please complete the reCAPTCHA challenge.');
      return;
    }
    console.log(event.target.checkValidity())

    try {

      const response = await fetch('https://branch-selector--contact.anish98821.workers.dev/', {
        method: 'POST',
        body: JSON.stringify(formData),
      });



      if (response.ok) {
        console.log(response.statusText)
        console.log('Form submitted successfully');
        navigate('/contact-confirmation')
        // Optionally, you can perform further actions upon successful submission
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };
  return (
    <div>
      <Layout>
        <div className='container'>
          <div className="contactContainer">
            <h2 id='contactHeading'>Please enter the following details</h2>
            <br />
            <form className='contactForm' onSubmit={handleSubmit}>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required placeholder='Name' ref={inputRef} />
              <br />
              <input type="text" name="college" value={formData.college} onChange={handleChange} required placeholder='College' />
              <br />
              <input type="email" name="email" value={formData.email} onChange={handleChange} required placeholder='Email' />
              <br />
              <input type="tel" name="phone" pattern="[0-9]{10}" value={formData.phone} onChange={handleChange} required placeholder='Phone Number' />
              <br />
              <ReCAPTCHA
                style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}
                ref={recaptchaRef}
                sitekey="6LckeGUpAAAAANUyCSTYSsbpdtmOoUmohV_xsE3L"
                onChange={handleCaptchaChange}
              />
              <br /> 
              <button id='contactSubmitBtn' type='submit' >Submit</button>
            </form>
          </div>
        </div>
      </Layout>
    </div>
  )
}
