import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider(props) {
  const handleSliderChange = (_, value) => {
    // Invoke the parent component's callback with the new slider value
    props.onSliderChange(value);
  };

  const marks = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ];

  return (
    <Box sx={{
      width: {
        xs: '95%',  // for phone devices
        md: 300,    // for desktop devices
      },
    }}>
      <Slider
        aria-label="Weights"
        value={props.value}  // Use the provided value prop here
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={1}
        max={5}
        onChange={handleSliderChange}
      />
    </Box>
  );
}
