import React, { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import Layout from '../../Common/Layout';


const InterestHomePage=()=>{
    const [typedIntro2, setTypedIntro2] = useState('');
    const [typedGrade2, setTypedGrade2] = useState('');
    const introText2='What are you truly ';
    const gradeText2="interested in?";
    const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0)
    
    const typeIntro = async (introText,setTypedIntro) => {
      for (let i = 0; i < introText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100)); 
        setTypedIntro(introText.substring(0, i + 1)); 
      }
    };

    const typeGrade = async (gradeText,setTypedGrade) => {
      for (let i = 0; i < gradeText.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        setTypedGrade(gradeText.substring(0, i + 1));
      }
    };

    typeIntro(introText2,setTypedIntro2);
    typeGrade(gradeText2,setTypedGrade2);
  }, []);
  var nextPage = {
    
  }
    return <Layout>
          <div className="firstContainer">    
      <h1 id="intro">{typedIntro2}</h1>
      <h1 id="grade">{typedGrade2}</h1>
      <p id="desc">Unlock the secrets of your passions and uncover the career path  <br /> that truly speaks to you with our enlightening interest inventory test!</p>
      <button id="takeQuizBtn" onClick={()=>navigate('/pre-test',{state:"InterestTest"})}>Take Test</button>
      
    </div>
    </Layout>
  
}

export default InterestHomePage;