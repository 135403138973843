import React from 'react'
import '../../Styles/BookingPageStyle.css'
import Layout from './Layout';
export default function AfterBooking()
{

    return (
        <Layout>
        <div className="afterbooking-container">
        <h1 id="bookingHeading">✅<br/>Your request has been received</h1>
        <h2>We will get back to you regarding your appointment</h2>
        </div>
        </Layout>

    );
}