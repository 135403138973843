import React, { useState, useEffect, useRef } from "react";
import "./Styles/QuizSVG.css";

export default function QuizSVG() {
  const [isActive, setIsActive] = useState(false);
  const svgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the component is in the view, setIsActive(true)
          if (entry.isIntersecting) {
            setIsActive(true);
          } else {
            // If the component is out of view, setIsActive(false)
            //setIsActive(false);
          }
        });
      },
      {
        // You can adjust the root, threshold, and other options as needed
        root: null,
        rootMargin: "0px",
        threshold: 1 // The percentage of the component that needs to be visible to trigger the callback
      }
    );

    // Start observing the SVG element
    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (

    <svg
      ref={svgRef}
      className={isActive ? "active" : ""}
      width="100"
      height="200"
      viewBox="0 0 163 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <rect x="1.5" y="1.5" width="160" height="227" rx="9.5" stroke="#2980b9" strokeWidth="3" className="svg-elem-1"></rect>
    <path d="M24 41H142V43H24V41Z" fill="#2980b9" className="svg-elem-2"></path>
    <circle cx="36" cy="42" r="5" fill="#2980b9" className="svg-elem-3"></circle>
    <path d="M24 69H142V71H24V69Z" fill="#2980b9" className="svg-elem-4"></path>
    <circle cx="123" cy="70" r="5" fill="#2980b9" className="svg-elem-5"></circle>
    <path d="M24 97H142V99H24V97Z" fill="#2980b9" className="svg-elem-6"></path>
    <circle cx="61" cy="98" r="5" fill="#2980b9" className="svg-elem-7"></circle>
    <path d="M24 125H142V127H24V125Z" fill="#2980b9" className="svg-elem-8"></path>
    <circle cx="36" cy="126" r="5" fill="#2980b9" className="svg-elem-9"></circle>
    <path d="M24 153H142V155H24V153Z" fill="#2980b9" className="svg-elem-10"></path>
    <circle cx="87" cy="154" r="5" fill="#2980b9" className="svg-elem-11"></circle>
    <path d="M24 181H142V183H24V181Z" fill="#2980b9" className="svg-elem-12"></path>
    <circle cx="123" cy="182" r="5" fill="#2980b9" className="svg-elem-13"></circle>
  </svg>

);
}