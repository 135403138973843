// import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Layout from './Layout';
import '../../Styles/Testlist.css'


export default function TestList() {
    const navigate = useNavigate();
    return (
        <Layout>
            <div className='testsmaindiv' style={{ display: "inline-block" }}>
                <h1 className='testlisthead'>Welcome to Our Comprehensive Collection of <br />Diverse Tests and Assessments</h1>
                <div className="testContainer">
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "EngineeringTest" })}>
                        <img src={require("../../Assets/engineer.png")} alt="Logo" height={70} />
                        <h3> Engineering Test</h3>
                    </div>
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "StreamTest" })}>
                        <img src={require("../../Assets/classXI.png")} alt="Logo" height={70} />
                        <h3>Class X Test</h3>
                    </div>
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "VARKTest" })}>
                        <img src={require("../../Assets/vark.png")} alt="Logo" height={70} />
                        <h3>VARK Test</h3>
                    </div>
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "BrainTest" })}>
                        <img src={require("../../Assets/brain.png")} alt="Logo" height={70} />
                        <h3>Left Right Brain Test</h3>
                    </div>
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "IQ" })} >
                        <img src={require("../../Assets/iq.png")} alt="Logo" height={70} />
                        <h3>IQ Test</h3>
                    </div>
                    <div className='testBox'onClick={() => navigate('/pre-test', { state: "PersonalityTest" })}>
                        <img src={require("../../Assets/memory.png")} alt="Logo" height={70} />
                        <h3>Personality Test</h3>
                    </div>
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "InterestTest" })}>
                        <img src={require("../../Assets/interest.png")} alt="Logo" height={70} />
                        <h3>Interest Test</h3>
                    </div>
                    <div className='testBox' onClick={() => navigate('/pre-test', { state: "StrengthTest" })}>
                        <img src={require("../../Assets/strength.png")} alt="Logo" height={70} />
                        <h3>Strength Test</h3>
                    </div>
                </div>
            </div>
        </Layout>
    )
}