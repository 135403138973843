import React from 'react'
import '../../Styles/BookingPageStyle.css'
import Layout from './Layout';

export default function AfterContact()
{

    return (
        <Layout>
        <div className="afterbooking-container">
        <h1 id="heading">✅<br/>Your request has been received</h1>
        <h2>We will get back to you soon.</h2>
        </div>
        </Layout>

    );
}   