import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect} from 'react';
import Layout from '../../Common/Layout';
import BrainSVG from '../../../SVGs/BrainSVG';

const BrainResultPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const fill_percentage =  (state.brainResults.Left/(state.brainResults.Left+state.brainResults.Right))*100
    const dominantPercentage = fill_percentage >50 ? fill_percentage : 100-fill_percentage
    const dominantBrain = fill_percentage > 50 ? "Left":"Right"
    const nonDominantBrain = dominantBrain === "Left"?"Right": "Left"

    useEffect(() => {

        window.scrollTo(0, 0);
    });

    function bookAppointment() {
        navigate('/booking');
    }

    return (
        <Layout>
            <div className='resultsContainer'>
                <h1 className='resultsHeading'>Your results are ready!🎉</h1>
                <div className='resultsText'>
                    Based on the questions you answered, here is the breakdown of your dominant brain hemisphere.
                    <br />
                    <br />
                    Our test indicate that you are dominantly {dominantBrain.toUpperCase()} brained
                </div>
                <div className="chartContainer">

                    <BrainSVG fillPerc={fill_percentage} />

                </div>
                <br></br>
                <div className='resultsText'>
                    Dominant ({dominantPercentage.toFixed(2)}%) : {dominantBrain} Hemisphere
                    <br />
                    Non Dominant ({(100 - dominantPercentage).toFixed(2)}%): {nonDominantBrain} Hemisphere
                </div>
            </div>

            <div className='bookingContainer'>
                <div className='counsellingHeading'>
                    Still confused? </div>
                <div className='counsellingText'>Book a counseling session for one-on-one personal guidance!</div>

                <button className='bookAppointment' onClick={() => bookAppointment()}>Book Appointment</button>
            </div>
        </Layout>
    );
};

export default BrainResultPage;
