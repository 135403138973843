import React from "react";
import { BsGrid1X2Fill } from "react-icons/bs";
import { SiTestcafe } from "react-icons/si";
import { MdOutlineDiscount } from "react-icons/md";
import { TbBookDownload } from "react-icons/tb";
import { MdOutlineMessage } from "react-icons/md";
import Logo from "./logo.png";
import { Link } from "react-router-dom";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const sidebarData = [
    {
      title: " Dashboard",
      icon: <BsGrid1X2Fill className="a_icon" />,
      link: "/admin",
    },
    {
      title: "Tests Taken",
      icon: <SiTestcafe className="icon" />,
      link: "/admin/test",
    },
    {
      title: "E-book Download",
      icon: <TbBookDownload className="icon" />,
      link: "/admin/bookDownload",
    },
    {
      title: "Voucher Code",
      icon: <MdOutlineDiscount className="a_icon" />,
      link: "/admin/voucher",
    },
    {
      title: "Blog",
      icon: <MdOutlineMessage className="a_icon" />,
      link: "/admin/blog",
    },
  ];
  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "a_sidebar-responsive" : ""}
    >
      <div className="a_sidebar-title">
        <div className="a_sidebar-brand">
          {/* <img className="a_logo" src={Logo} height={50} width={50} alt="logo" /> */}
          BranchSelector
        </div>
        <span className="a_icon a_close_icon" onClick={OpenSidebar}>
          X
        </span>
      </div>

      {sidebarData.map((item, index) => (
        <ul key={index} className="a_sidebar-list">
          <li className="a_sidebar-list-item" onClick={OpenSidebar}>
            <Link to={item.link} >
              {item.icon} {item.title}
            </Link>
          </li>
        </ul>
      ))}
    </aside>
  );
}

export default Sidebar;
