import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Questions from "./EngineeringTestQuestions";
import Layout from "../../Common/Layout";
import "../../../Styles/TestPages.css";

import {
  canTakeTest,
  getCurrentUserInfo,
  newTestTaken,
} from "../../../services/userService";
import { getDefaultProfilePic } from "../testService";
import { TestSideBar } from "../TestSideBar/TestSideBar";
import { toast } from "react-toastify";

async function fetchQuestions() {
  const response = await fetch(
    "https://branchselector--get-questions.anish98821.workers.dev/?query=engineering"
  );
  const data = await response.json();
  return data;
}

export default function EngineeringTestPage() {
  const [fetchedQuestions, setFetchedQuestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sliderValues, setSliderValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 5;

  const [userDetails, setUserDetails] = useState(null);

  var isLastPage;

  const navigate = useNavigate();
  useEffect(() => {
    const checkAllowed = async () => {
      try {
        const validity = await canTakeTest();
        if (validity) {
        } else {
          navigate("/dashboard");
          toast.error("No more free tests left on the account");
        }
      } catch (error) {
        console.error("Not premium", error);
        setLoading(false);
      }
    };

    checkAllowed();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questions = await fetchQuestions();
        setFetchedQuestions(questions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching questions:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDetails = await getCurrentUserInfo();
        console.log(userDetails);
        setUserDetails(userDetails);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchUserData();
  }, userDetails);

  useEffect(() => {
    if (fetchedQuestions) {
      setSliderValues(new Array(fetchedQuestions.length).fill(3));
    }
  }, [fetchedQuestions]);

  const handleSliderChange = (index, value) => {
    setSliderValues((prevValues) => {
      const newSliderValues = [...prevValues];
      newSliderValues[index] = value;
      return newSliderValues;
    });
  };

  const handleNextPage = () => {
    window.scroll(0, 0);
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(fetchedQuestions.length / questionsPerPage)
      )
    );
  };

  const handlePrevPage = () => {
    window.scroll(0, 0);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const renderQuestions = () => {
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    isLastPage =
      currentPage === Math.ceil(fetchedQuestions.length / questionsPerPage);
    return fetchedQuestions
      .slice(startIndex, endIndex)
      .map((question, index) => (
        <Questions
          key={startIndex + index}
          question={question}
          sliderValue={sliderValues[startIndex + index]}
          onSliderChange={(value) =>
            handleSliderChange(startIndex + index, value)
          }
        />
      ));
  };

  const sendApiRequest = (url) => {
    //
    const user = localStorage.getItem("user");
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: user,
    };
    //
    return fetch(url, options).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    });
  };

  const handleAddToList = () => {
    const apiUrl =
      "https://branchselctor--get-results.anish98821.workers.dev/?query=engineering&Score=";

    for (var i = 0; i < sliderValues.length; i++)
      sliderValues[i] = sliderValues[i] - 3;

    var allZero = sliderValues.every((element) => element == 0);

    const apiWithScoreUrl =
      apiUrl +
      JSON.stringify(
        allZero ? new Array(sliderValues.length).fill(1) : sliderValues
      );

    //console.log(apiWithScoreUrl)
    sendApiRequest(apiWithScoreUrl)
      .then((apiResponse) => {
        navigate("/engineering/results", { state: { apiResponse } });
      })
      .catch((error) => {
        console.error(error.message);
      });
    // let tempResult = JSON.stringify(apiResponse);
    newTestTaken("Engineering Test", "temp");
  };

  return (
    <Layout>
      {userDetails != null && !loading ? (
        <div className="test-main-container">
          <TestSideBar
            profilePic={
              userDetails["photoUrl"]
                ? userDetails["photoUrl"]
                : getDefaultProfilePic()
            }
            userName={
              userDetails["displayName"] ? userDetails["displayName"] : "User"
            }
            email={
              userDetails["email"] ? userDetails["email"] : "user@mail.com"
            }
            testName={"Engineering Test"}
            answeredQuestions={5}
            totalQuestions={15}
          />
          <div className="test-container">
            {currentPage == 1 && (
              <>
                <h1 id="instruct">Instructions!</h1>
                <br />
                <div id="instructionTxt">
                  <div id="instructionContents">
                    <ul>
                      <li>
                        &nbsp;The scoring is as follows : <br />
                        &nbsp;&nbsp;1 : Completely Disinterested 2 : Slightly
                        Disinterested 3 : Neutral 4 : Slightly Interested 5 :
                        Very Interested.
                      </li>
                      <li>
                        &nbsp;While answering the test, be honest. Answer to
                        express, not to impress.
                      </li>
                      <li>
                        &nbsp;Do not ask for suggestions with friends/check
                        online for answers while answering the questions.
                      </li>
                      <li>
                        &nbsp;Take the test 2 times to get better understanding
                        of your preferences.
                      </li>
                      <li>
                        &nbsp;Read the e-Book from the Downloads section to
                        understand the various branches and the career
                        opportunities.
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
            <br />
            {fetchedQuestions && (
              <h3 id="questionHeading">
                Please answer the following questions ({currentPage}/
                {Math.ceil(fetchedQuestions.length / questionsPerPage)})
              </h3>
            )}
            <br />
            {loading ? (
              <p id="loadTxt">Loading...</p>
            ) : (
              <div>
                {fetchedQuestions && <div>{renderQuestions()}</div>}
                <br />
                <div className="takeQuizBtns">
                  <div className="backNextBtns">
                    <button
                      id="backBtn"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Back
                    </button>
                    {!isLastPage && (
                      <button
                        id="nextBtn"
                        onClick={handleNextPage}
                        disabled={
                          currentPage ===
                          Math.ceil(fetchedQuestions.length / questionsPerPage)
                        }
                      >
                        Next
                      </button>
                    )}
                  </div>
                  <div className="getResultBtn">
                    {isLastPage && (
                      <button id="resultBtn" onClick={handleAddToList}>
                        Get Result
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <br />
          </div>
        </div>
      ) : (
        <div className="loading-screen">Loading...</div>
      )}
    </Layout>
  );
}
