import React, { useState, useEffect, useRef } from "react";
import "./Styles/MissionSVG.css";

export default function MissionSVG() {
  const [isActive, setIsActive] = useState(false);
  const svgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the component is in the view, setIsActive(true)
          if (entry.isIntersecting) {
            setIsActive(true);
          } else {
            // If the component is out of view, setIsActive(false)
            //setIsActive(false);
          }
        });
      },
      {
        // You can adjust the root, threshold, and other options as needed
        root: null,
        rootMargin: "0px",
        threshold: 1, // The percentage of the component that needs to be visible to trigger the callback
      }
    );

    // Start observing the SVG element
    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (

    <svg
    ref={svgRef}
    className={isActive ? 'active' : ''}
    width="200"
    height="130"
    viewBox="0 0 260 219"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 8L46.9572 133.366C48.2307 136.878 51.1998 139.504 54.8413 140.339L89.0216 148.172C93.757 149.257 97.2197 153.32 97.5405 158.167L101.5 218"
      stroke="#2980b9"
      strokeWidth="3"
      className="misson-logo-svg-1"
    ></path>
    <path
      d="M157 218L156 188V158.68C156 153.59 159.492 149.165 164.442 147.981L196.619 140.287C200.004 139.477 202.807 137.113 204.175 133.912L258 8"
      stroke="#2980b9"
      strokeWidth="3"
      className="misson-logo-svg-1"
    ></path>
    <path
      d="M202 1L169.848 78.6597C169.617 79.2181 169.323 79.7487 168.973 80.2414V80.2414C164.604 86.3903 154.936 83.6829 154.396 76.1593L149 1"
      stroke="#2980b9"
      strokeWidth="3"
      className="misson-logo-svg-1"
    ></path>
    <path
      d="M107 1L101.538 77.0842C101.096 83.2343 92.8718 84.9563 90 79.5V79.5L49 1"
      stroke="#2980b9"
      strokeWidth="3"
      className="misson-logo-svg-1"
    ></path>
    <path
      d="M57 78L55.9745 89.5014L66.4477 84.6388L57 78ZM69.093 106.421C69.3256 106.922 69.9202 107.14 70.4211 106.907C70.922 106.674 71.1396 106.08 70.907 105.579L69.093 106.421ZM59.883 86.5842L69.093 106.421L70.907 105.579L61.697 85.742L59.883 86.5842Z"
      fill="#2980b9"
      className="misson-logo-svg-2"
    ></path>
    <path
      d="M199 78L188.736 83.2897L198.449 89.5338L199 78ZM180.159 105.459C179.86 105.924 179.995 106.543 180.459 106.841C180.924 107.14 181.543 107.005 181.841 106.541L180.159 105.459ZM193.292 85.0298L180.159 105.459L181.841 106.541L194.974 86.1114L193.292 85.0298Z"
      fill="#2980b9"
      className="misson-logo-svg-3"
    ></path>
    <path
      d="M130 78L124.226 88L135.774 88L130 78ZM129 106C129 106.552 129.448 107 130 107C130.552 107 131 106.552 131 106L129 106ZM129 87L129 106L131 106L131 87L129 87Z"
      fill="#2980b9"
      className="misson-logo-svg-4"
    ></path>
  </svg>

);
}