import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { useRef, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import "../../Styles/PreTestStyle.css";

const PreTest = () => {
  const inputRef = useRef(null);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const handleCaptchaChange = () => {
    setIsCaptchaChecked(true);
  };
  const recaptchaRef = React.createRef();
  const { state } = useLocation()


  useEffect(
    () => { inputRef.current.focus() }
    , [])
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    college: '',
    email: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = async (event) => {

    event.preventDefault();
    if (!isCaptchaChecked) {
      alert('Please complete the reCAPTCHA challenge.');
      return;
    }
    //event.target.checkValidity()

    try {
      const user = JSON.stringify(formData);
      localStorage.setItem("user", user);
      const response = await fetch('https://branch-selector--new-test-taker.anish98821.workers.dev/', {
        method: 'POST',
        body: JSON.stringify(formData),
      });



      if (response.ok) {
        console.log(response.statusText)
        console.log('Form submitted successfully');

        switch (state) {
          case 'BrainTest':
            navigate('/brain/test');
            break;
          case 'VARKTest':
            navigate('/vark/test');
            break;
          case 'StreamTest':
            navigate('/ClassX/test');
            break;
          case 'EngineeringTest':
            navigate('/engineering/test');
            break;

          case 'PersonalityTest':
              navigate('/personality/test')
              break;

          case 'InterestTest':
            navigate('/interest/test');
            break;

          case 'StrengthTest':
            navigate('/strength/test');
            break;
          case 'IQ':
            navigate('/IQ/test');
            break;
          default:
            // Handle default case if needed
            break;
        }

        // Optionally, you can perform further actions upon successful submission
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  return (
    <Layout>
      <div className='container'>
        <div className="preTestContainer">
          <h1 id='preTestHeading'>Please enter the following details</h1>
          <br />
          <form className='preTestForm' onSubmit={handleSubmit}>

            <input type="text" name="name" value={formData.name} onChange={handleChange} required placeholder='Name' ref={inputRef} />
            <br />

            <input type="text" name="college" value={formData.college} onChange={handleChange} required placeholder='College' />
            <br />

            <input type="email" name="email" value={formData.email} onChange={handleChange} required placeholder='Email' />

            <br />
            <div className="disclaimer">
              <p id='disclaimerTxt'>Disclaimer: <br />
                Welcome to Branch Selector! Before you proceed, please read and understand the following disclaimer: <br />
                Purpose: Branch Selector is an artificial intelligence-based tool designed to assist students in exploring potential engineering branches based on their answers to a specific set of questions. The tool aims to provide suggestions and insights to help users make informed decisions about their academic and career paths. <br />
                Advisory Nature: The recommendations provided by Branch Selector are purely advisory in nature and should not be considered as definitive or absolute. The tool analyzes user responses to generate suggestions but does not replace the need for personalized guidance from educational professionals, career counselors, or other experts. <br />
                Limitations: Branch Selector relies on the data provided by users in response to the questions presented. The accuracy and relevance of the suggestions are dependent on the completeness and accuracy of the user's responses. The tool does not consider external factors, individual circumstances, or changes in academic programs that may occur after the tool's last update. <br />
                Not a Substitute for Professional Advice: The suggestions provided by Branch Selector are not a substitute for professional advice. Users are encouraged to consult with qualified professionals, such as academic advisors or career counselors, to receive personalized guidance based on their unique situations. <br />
                Dynamic Nature of Information: The field of education and engineering is dynamic, and changes may occur in academic programs, industry demands, and career opportunities. Branch Selector may not reflect the most current information, and users are advised to verify information independently. <br />
                No Guarantee of Outcomes: The suggestions provided by Branch Selector do not guarantee specific outcomes or success in selected engineering branches. The ultimate decision regarding academic and career choices rests with the user, and individual results may vary. <br />
                Use at Your Own Risk: The use of Branch Selector is at the user's own risk. The tool, its developers, and affiliated parties are not responsible for any decisions made by users based on the suggestions provided. <br />
                By using Branch Selector, you acknowledge that you have read, understood, and agreed to this disclaimer. If you do not agree with any part of this disclaimer, we advise against using the tool.</p>
            </div>
            <br />
            <label>
              <input type="checkbox" name='agree' required></input>
              &nbsp;&nbsp;<span id='confirmTxt'>I have read and understood the disclaimer.</span>
            </label>

            <ReCAPTCHA
              style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}
              ref={recaptchaRef}
              sitekey="6LckeGUpAAAAANUyCSTYSsbpdtmOoUmohV_xsE3L"
              onChange={handleCaptchaChange}
            />
            <button id='preTestSubBtn' type='submit' >Submit</button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default PreTest;
