import React, { useEffect, useState } from 'react';
import './blog.css';
import {getBlogPosts} from  './../../services/adminServices';
import PostCard from './PostCard';
import Footer from './../Common/Footer';




// @firebase fetching
// ------------------------------------------------------------------------------------------------------------------------------------



// const PAGE_SIZE = 10; // Number of items per page

const BlogContent = () => {
    const [posts, setPosts] = useState([]);
    // const [lastPost, setLastPost] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const allPost = await getBlogPosts();
                if (allPost.length > 0) {
                    setPosts(allPost);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
        fetchPosts(); // Call the async function
        // Empty dependency array ensures this runs only once on component mount
    }, []);
    
    
     
    console.log("allBlogPost",posts);
    return (
        <section className='main-content'>
            <div className='left-heading'>
                <p id='featured'>Featured Posts</p>
            </div>
            <section className='post-content'>
                <div className='PostCard-container'>
                    {posts.map( post => (
                        <PostCard key={post.id} cardContent={post.content} />
                    ))}
                </div>
            </section>
            {/* <div className="pagination-buttons">
                <button onClick={fetchPreviousPage} disabled={!lastPost}>Previous</button>
                <button onClick={fetchNextPage}>Next</button>
            </div> */}
        </section>
    );
};





// @ui 
// ------------------------------------------------------------------------------------------------------------------------------------




const BlogNav = () => {
    return (
        <div className='blog-navigation'>
            <Tag name={"Latest Posts"} />
            <Tag name={"Featured"} />
            <Tag name={"2024"} />
        </div>
    );
};

const Subscribe = () => {
    return (
        <div className='subscribe'>
            <div className='form'>
                <form>
                    <span className='title'>Subcribe To Our Newsletter.</span>
                    <div>
                        <input className='input-box' placeholder="Enter your email" type="email" name="email" id="email-address" />
                        <button type="submit" >Subscribe</button>
                    </div>
                </form>
            </div>
        </div>
    );
};


const Tag = ({ name }) => {
    return (
        <span className='tag'>{name}</span>
    );
};

const BlogLanding = () => {
    return (
        <section className='landing-page'>
            <section className='landing-subcontainer'>
                <Landing />
            </section>
        </section>
    )
};



const Landing = () => {
    return (
        <div className='landing-content1'>
            <div className='blog-text'>
                <h1 id='blog-heading' className='foggy-heading'>Discover Your Path with Branch Selector</h1>
                <h4 id='blog-description'>Empowering students to uncover their passion, navigate academic and career choices, and find their ideal path of study or career field. With personalized guidance, we empower you to make informed decisions, charting your course to success.</h4>
            </div>
            <Subscribe />
        </div>
    );
};

const Blog = () => {
    return (
        <section className='main-blog'>
            <BlogNav />
            <BlogLanding />
            <BlogContent />
            <Footer />
        </section>
    );
};

export default Blog;
