import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect } from 'react';

import {useLocation, useNavigate } from 'react-router-dom';
import '../../../Styles/ResultsPage.css'

import Layout from '../../Common/Layout';
import Chart from 'chart.js/auto';
import { useRef } from 'react';
ChartJS.register(ArcElement, Tooltip, Legend);

export default function EngineeringResultPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { state } = useLocation();
  const chartRef = useRef(null);
  const charInstance = useRef(null);
  const navigate = useNavigate();
  const recivedData = state?.apiResponse || null;

  function normalize(data, minRange, maxRange) {
    let minVal = Math.min(...data);
    let maxVal = Math.max(...data);
    
    let normalizedData = [];
    data.forEach(function(value) {
        let normalizedValue = ((value - minVal) / (maxVal - minVal)) * (maxRange - minRange) + minRange;
        normalizedData.push(normalizedValue);
    });
    
    return normalizedData;
  }

  console.log(recivedData);
  useEffect(() => {
    if (charInstance.current) {
      charInstance.current.destroy();
    }

    const numericalValues = [recivedData[0][1], recivedData[1][1], recivedData[2][1]]

    const norm = normalize(numericalValues,1,20)
    const myChartRef = chartRef.current.getContext('2d')

    const barColors = [
      '#4ea8de',
      '#ffeb3b',
      '#8bc34a',
      '#9c27b0',
      '#ff9800'
    ];
    charInstance.current = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: [recivedData[0][0], recivedData[1][0], recivedData[2][0]],
        datasets: [
          {
            label: 'Branches',
            data: [norm[0],norm[1],norm[2]],
            backgroundColor: barColors
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: true
          },
        },
        scales: {
          x: {
            display: window.innerWidth > 768, // Hide x-axis labels on small screens
            ticks: {
              color: 'black',
              display: window.innerWidth > 768, // Hide ticks along the x-axis
            }
          },
          y: {
            ticks: {
              color: 'black'
            }
          }

        }


      }
    })
    // Chart.canvas.parentNode.style.height = '128px';
    // Chart.canvas.parentNode.style.width = '128px';
    return () => {
      if (charInstance.current) {
        charInstance.current.destroy()
      }
  }
})

  // const { state } = useLocation();

  // console.log(`${recivedData[0][0]} ,${recivedData[1][0]},${recivedData[2][0]}`);

  // const options = {
  //   plugins: {
  //     datalabels: {
  //       color: '#ffff',
  //       formatter: (value, context) => {
  //         return ((value / context.dataset.data.reduce((a, b) => a + b) * 100).toFixed(2) + '%');
  //       },
  //     }
  //   }
  // }


  //  const data = {
  //     labels: [recivedData[0][0],recivedData[1][0],recivedData[2][0]],
  //     datasets: [
  //       {
  //         label: '# of Votes',
  //         data: [recivedData[0][1],recivedData[1][1],recivedData[2][1]],
  //         backgroundColor: [
  //           'rgba(255, 99, 132, 0.2)',
  //           'rgba(54, 162, 235, 0.2)',
  //           'rgba(255, 206, 86, 0.2)',
  //         ],
  //         borderColor: [
  //           'rgba(255, 99, 132, 1)',
  //           'rgba(54, 162, 235, 1)',
  //           'rgba(255, 206, 86, 1)',

  //         ],
  //         borderWidth: 1,
  //       },
  //     ],
  //   };


  //   const plugins = {

  // }
  function bookAppointment() {
    navigate('/booking')
  }

  return (
    <Layout>
      <div className='resultsContainer'>
        <h1 className='resultsHeading'>Your results are ready!🎉</h1>
        <div className='resultsText'>Based on the questions you answered, here are the top 3 engineering branches that will suit your interests the best!
          <div className='resultsBranches'>1.{" " + recivedData[0][0]}<br />2.{" " + recivedData[1][0]}<br />3.{" " + recivedData[2][0]}</div>
          Here is a breakdown of how of suitable each of these branches are!
        </div>
        <div className="chartContainer">
          {/* <Pie data={data} options = {options} plugins={[ChartDataLabels]}/>  */}
          <canvas id="myChart" ref={chartRef} style={{ width: '70vw', maxHeight: '60vh', padding: 'auto' }}></canvas>
          <div className='branchboxes' style={{ marginTop: '20px', padding: '10px', display: window.innerWidth <= 768 ? 'block' : 'none' }}>
            <div style={{ backgroundColor: '#4ea8de', color: 'white', padding: '10px', marginBottom: '20px', display: 'inline-block', border: '1px solid white' }}>{recivedData[0][0]}</div><br/>
            <div style={{ backgroundColor: '#ffeb3b', color: 'white', padding: '10px', marginBottom: '20px', display: 'inline-block', border: '1px solid white' }}>{recivedData[1][0]}</div><br/>
            <div style={{ backgroundColor: '#8bc34a', color: 'white', padding: '10px', marginBottom: '20px', display: 'inline-block', border: '1px solid white' }}>{recivedData[2][0]}</div><br/>
            <div style={{ backgroundColor: '#9c27b0', color: 'white', padding: '10px', marginBottom: '20px', display: 'inline-block', border: '1px solid white' }}>{recivedData[3][0]}</div><br/>
            <div style={{ backgroundColor: '#ff9800', color: 'white', padding: '10px', marginBottom: '20px', display: 'inline-block', border: '1px solid white' }}>{recivedData[4][0]}</div>
          </div>
        </div>

        <div className='resultsText' style={{ marginTop: '20px' }}>You may also consider:</div>
        <div className='resultsBranches'>4.{" " + recivedData[3][0]}<br />5.{" " + recivedData[4][0]}</div>
      </div>
      <div className='bookingContainer'>
        <div className='counsellingHeading'>
          Still confused?
        </div>
        <div className='counsellingText'>Book a counselling session for one on one personal guidence!</div>

        <button className='bookAppointment' onClick={bookAppointment}>Book Appointment</button>
      </div>
    </Layout>
  )
}