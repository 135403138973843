import React from 'react'
import { Link } from 'react-router-dom';
import panamaLogo from '../../Logos/PanamaLogo.png';
import sahyadriLogo from '../../Logos/SahyadriLogo.png';
import surePassLogo from '../../Logos/SurePassLogo.png';
import torsecureLogo from '../../Logos/TorsecureLogo.png'; 
import panamaNewLogo from '../../Logos/panama-new-logo.png';

import './../../Styles/SponsorsStyle.css';



export default function Sponsors() {
    return (
        <div className='sponsorsContainer'>
            <p id='sponsorsTxt'>Our Sponsors</p> 
            <div className='sponsors-image-band'>
           <img src={panamaNewLogo} onClick={()=>window.open('https://panamacorporationltd.com/')} id = 'panama-sponsor-logo' className = 'sponsors-logo' alt ='Panama Corporation'/>
           <img src={sahyadriLogo} onClick={()=>window.open('http://www.sahyadri.edu.in/')} id = 'sahyadri-sponsor-logo' className = 'sponsors-logo' alt ='Sahyadri College'/>
           <img src={surePassLogo} onClick={()=>window.open('http://www.thesurepass.com/')} id = 'surepass-sponsor-logo' className = 'sponsors-logo'alt ='SurePass'/>
           <img src={torsecureLogo} onClick={()=>window.open('http://www.torsecure.com/')} id = 'torsecure-sponsor-logo' className = 'sponsors-logo' alt ='Tosecure'/>
           </div>
        </div>
    )
}
