const InterestTestQuestion=({item,key,radioValue, onRadioChange})=>{
    return (
    <div  className = 'questionComponent' >
                                    
            <div className='questionBox'><p id='questionTxt'>{item.question}</p></div>
            
            <div style={{textAlign:'left',paddingLeft:'15px'}}>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[0].type)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[0].type} checked={radioValue === item.options[0].type} onChange={() => onRadioChange(item.options[0].type)}></input>{item.options[0].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[1].type)}> <input type="radio" name={key} style={{marginRight:'10px'}}  value={item.options[1].type} checked={radioValue === item.options[1].type} onChange={() => onRadioChange(item.options[1].type)} ></input>{item.options[1].text} </div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[2].type)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[2].type} checked={radioValue === item.options[2].type} onChange={() => onRadioChange(item.options[2].type)}></input>{item.options[2].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[3].type)} > <input type="radio" name={key} style={{marginRight:'10px'}}  value={item.options[3].type} checked={radioValue === item.options[3].type} onChange={() => onRadioChange(item.options[3].type)} ></input>{item.options[3].text} </div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[4].type)}><input type="radio"  name={key} style={{marginRight:'10px'}} value={item.options[4].type} checked={radioValue === item.options[4].type} onChange={() => onRadioChange(item.options[4].type)}></input>{item.options[4].text}</div>
            <div id='optionTxt' onClick={() => onRadioChange(item.options[5].type)} > <input type="radio" name={key} style={{marginRight:'10px'}}  value={item.options[5].type} checked={radioValue === item.options[5].type} onChange={() => onRadioChange(item.options[5].type)} ></input>{item.options[5].text} </div>
            </div>

            
        
        </div>
    )
    }
    
    export default InterestTestQuestion;