import React from "react";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlinePaid } from "react-icons/md";
import { SiTestcafe } from "react-icons/si";
import { TbBookDownload } from "react-icons/tb";
import Data from "./user.json";

function Home() {
  const data = Data;
  const cardData = [
    {
      title: "Users",
      value: 500,
      icon: <BsPeopleFill className="a_card_icon" />,
    },
    {
      title: "Tests Taken",
      value: 1000,
      icon: <SiTestcafe className="a_card_icon" />,
    },
    {
      title: "E-book Download",
      value: 700,
      icon: <TbBookDownload className="a_card_icon" />,
    },
    {
      title: "Paid Users",
      value: 100,
      icon: <MdOutlinePaid className="a_card_icon" />,
    },
  ];
  return (
    <main className="a_main-container">
      <div className="a_main-cards">
        {cardData.map((item) => (
          <div className="a_card">
            <div className="a_card-inner">
              <h3>{item.title}</h3>
              {item.icon}
            </div>
            <h1>{item.value}</h1>
          </div>
        ))}
      </div>

      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr>
                <td>{item.ID}</td>
                <td>{item.Name}</td>
                <td>{item.Email}</td>
                <td>{item.Phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default Home;
