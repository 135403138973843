import React, { useState } from 'react';
import {
    signInWithGoogle,
    loginWithEmailAndPassword,
    isSignedIn,
    logout,
} from '../../../services/authService.js';
import { useLocation, useNavigate } from 'react-router-dom';

//import './AuthPage.css';

import logo from '../../../Assets/logo192.png';
import login_illustration from '../../../Assets/login_illustration.svg'

const AuthTesting = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorVar, setError] = useState(false)

    const location = useLocation();
    const navigate = useNavigate();
    const intendedDestination = location.state?.intendedDestination || '/';

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);



    const handleSignIn = async () => {
        try{
         await loginWithEmailAndPassword(email, password);
        }
        catch(error){
            setError(true)
        }
 
   
    };

    const handleGoogleSignIn = async () => {
        try{
        var result = await signInWithGoogle();
 
        }
        catch(error)
        {

        }
        console.log(result)
    };

  

    return (
    <>
    
    <div className='mainContainer'>
        <div className='left'>
          <img className='login-illustration' src = {login_illustration}></img>
        </div>
        <div className='right'>
            <div className='login-window'>
                <img src={logo} className='logo'></img>
                <h1 className='login-heading'>Login</h1>
                <input className = 'login-email' type='email' placeholder='email' onChange={handleEmailChange}></input>
                <input className = 'login-password' type='password' placeholder='password' onChange={handlePasswordChange}></input>
                <button className='login-button' onClick={handleSignIn}>Login</button>
                <div className='seperator'>
                <hr className='line'/>
                </div>
                <button className='login-with-google'>
                
                    <img className = 'image-holder' src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg'></img>
              
                    <span className='login-with-google-text' onClick={handleGoogleSignIn}>
                    Sign in with Google
                    </span>
             
                </button>
                <button onClick={()=>console.log(isSignedIn())}>isSigh</button>
                <button onClick = {logout}>Logout</button>
                <span className='new-account-propmt'>
                    Dont have an account? <a href='/signup'  className='sign-up-link'>Sign up here.</a>
                </span>
                {
                errorVar&&
                <span className='error-message'>
                    Invalid credentials. Try again.
                </span>
                }
            </div>
        </div>
    </div>
    </>
    );
};

export default AuthTesting;
